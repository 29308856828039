import { green, red } from '@ant-design/colors';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, InputNumber, Space, message } from 'antd';
import { useField, useFormikContext } from 'formik';
import { InvOnHandResp } from 'models/DeploymentRequestConfig';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { ProductPayload } from 'models/Product';
import { SparingRequestPayload } from 'models/SparingRequests';
import { useGetInventoryQuery } from 'redux/services/mrJohnson/mrJohnsonApi';
import { useAppSelector } from 'redux/store';
import { ProductCartCard } from '../../common/ProductCartCard';

interface Props {
  product: ProductPayload;
}

const styles: InlineStylesModel = {
  plusButton: {
    backgroundColor: green.primary,
    color: 'white'
  },
  minusButton: {
    backgroundColor: red.primary,
    color: 'white'
  },
  bottomControls: {
    justifyContent: 'flex-end'
  },
  quantityInput: {
    width: '70px',
    textAlign: 'center'
  }
};

export const ProductCartItem = ({ product }: Props): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { setFieldValue, values } = useFormikContext<SparingRequestPayload>();
  const { fullShippingAddress } = useAppSelector((state) => state.form);

  const [{ value }, , { setValue }] = useField(`lineItems.${values.lineItems?.findIndex((item) => item.id === product.id)}`);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  const { data, isLoading } = useGetInventoryQuery(
    {
      product: product.productNumber.toUpperCase(),
      typeId: values.sparingRequestTypeId as string,
      payload: { shippingAddress: fullShippingAddress as any, sparingDeliveryOption: 'SumOfAll' }
    },
    { skip: !values.sparingRequestTypeId || !fullShippingAddress }
  );

  const handleGetTotalQuantity = (allInventoryTotals: InvOnHandResp[]): number => {
    if (allInventoryTotals.length === 0) return 0;
    else {
      const sumTotal = (arr: any[]) => arr.reduce((sum, { quantityAvailable }) => sum + quantityAvailable, 0);
      const totalInventory = sumTotal(allInventoryTotals);

      return totalInventory;
    }
  };

  /* ******************** Functions ******************** */

  const handleRemoveProduct = (): void => {
    setFieldValue(
      'lineItems' as keyof SparingRequestPayload,
      values.lineItems?.filter((item) => item.id !== product.id)
    );
  };

  const handleChangeQuantity = (quantity: number | null): void => {
    if (quantity) {
      if (quantity > handleGetTotalQuantity(data ?? [])) {
        message.error(`Quantity cannot be greater than available quantity`);

        setValue({ ...value, quantity: handleGetTotalQuantity(data ?? []) });

        return;
      }

      setValue({ ...value, quantity });
    }
  };

  return (
    <ProductCartCard isLoading={isLoading} availableQuantity={handleGetTotalQuantity(data ?? [])} product={product}>
      <Space style={styles.bottomControls}>
        <InputNumber style={styles.quantityInput} value={product.quantity} type="int" min={1} precision={0} onChange={handleChangeQuantity} />
        <Button onClick={handleRemoveProduct} icon={<DeleteOutlined style={{ color: red.primary }} />} />
      </Space>
    </ProductCartCard>
  );
};
