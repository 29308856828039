import { Button, Col, Empty, Row } from 'antd';
import { useFormikContext } from 'formik';
import { DeploymentRequestPayload } from 'models/DeploymentRequest';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { setProductsView } from 'redux/slices/formSlice';
import { useAppDispatch } from 'redux/store';
import { ProductCartItem } from './components';

const styles: InlineStylesModel = {
  container: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    flex: 1,
    alignContent: 'flex-start'
  },
  emptyContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

export const ProductsCart = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { values } = useFormikContext<DeploymentRequestPayload>();

  const dispatch = useAppDispatch();

  /* ******************** Functions ******************** */
  const handleShowSearch = (): void => {
    dispatch(setProductsView('grid'));
  };

  /* ******************** Render Empty ******************** */
  if (!values.lineItems?.length) {
    return (
      <div style={styles.emptyContainer}>
        <Empty description="Cart is Empty">
          <Button onClick={handleShowSearch}>Select Products</Button>
        </Empty>
      </div>
    );
  }

  /* ******************** Render Data ******************** */
  return (
    <Row gutter={[16, 4]} style={styles.container}>
      {values.lineItems.map((product) => (
        <Col span={24} key={product.id}>
          <ProductCartItem product={product} />
        </Col>
      ))}
    </Row>
  );
};
