import { InputNumber, InputNumberProps, notification } from 'antd';
import { valueType } from 'antd/lib/statistic/utils';
import { useField } from 'formik';
import { ProductPayload } from 'models/Product';
import { SublinePayload } from 'models/SparingRequests';
import { FC } from 'react';

interface Props extends InputNumberProps {
  record: Partial<ProductPayload>;
  idx: number;
}

export const NFOQuantityInput: FC<Props> = ({ record, idx, ...rest }) => {
  const [{ value: formVal }, , { setValue }] = useField<SublinePayload[]>(`lineItems[${idx}].subLines`);
  const found = formVal?.find((item) => item.serviceLevelAgreement.toLowerCase() === 'NextFlightOut'.toLowerCase());

  const handleGetTotalQuantity = (allInventoryTotals: SublinePayload[]): number => {
    if (allInventoryTotals.length === 0) return 0;
    else {
      const sumTotal = (arr: SublinePayload[]): number => arr.reduce((sum, { quantity }) => sum + (quantity ?? 0), 0);
      const totalInventory = sumTotal(allInventoryTotals);

      return totalInventory;
    }
  };

  return (
    <InputNumber
      onKeyDown={(key): void => {
        if (!key.code.includes('Digit') && !key.code.includes('Arrow') && !key.code.includes('Backspace')) {
          key.preventDefault();
        }
      }}
      onChange={(value: valueType | null): void => {
        if (!value) {
          setValue(
            formVal?.filter((item) => {
              return item.serviceLevelAgreement.toLowerCase() !== 'NextFlightOut'.toLowerCase();
            })
          );

          return;
        }

        if (found) {
          setValue(
            formVal?.map((item) => {
              if (item.serviceLevelAgreement.toLowerCase() === 'NextFlightOut'.toLowerCase()) {
                return { ...item, quantity: Number(value) };
              }

              return item;
            })
          );

          return;
        }

        setValue([...(formVal ?? []), { quantity: Number(value), serviceLevelAgreement: 'NextFlightOut' }]);
      }}
      min={1}
      value={found?.quantity ?? undefined}
      onBlur={(): void => {
        if ((found?.quantity && record.quantity && found.quantity > record?.quantity) || (record.quantity && handleGetTotalQuantity(formVal ?? []) > record.quantity)) {
          setValue(
            formVal?.filter((item) => {
              return item.serviceLevelAgreement.toLowerCase() !== 'NextFlightOut'.toLowerCase();
            })
          );
          notification.error({
            message: 'Error',
            description: 'Cannot request more than ordered quantity',
            className: 'custom-class',
            style: {
              width: 600
            },
            duration: 10
          });

          return;
        }
      }}
      controls={false}
      {...rest}
    />
  );
};
