import { Card, Col, Row, Typography } from 'antd';

interface Props {
  children: React.ReactNode;
  headerColor: string;
  title: string;
}

export const ColoredHeaderCard: React.FC<Props> = ({ children, headerColor, title }) => {
  return (
    <Card
      headStyle={{ backgroundColor: headerColor }}
      title={
        <Row justify="space-between">
          <Col>
            <Typography.Title level={4} style={{ fontWeight: 'normal' }}>
              {title}
            </Typography.Title>
          </Col>
        </Row>
      }
      style={{ height: '100%' }}>
      {children}
    </Card>
  );
};
