import TextArea, { TextAreaProps } from 'antd/lib/input/TextArea';
import { Field, FieldProps } from 'formik';
import { FC } from 'react';
import { FormLabel } from './FormLabel';

interface Props extends TextAreaProps {
  fieldName: string;
  label: string;
}

export const FieldInputTextArea: FC<Props> = ({ fieldName, label, ...rest }) => {
  return (
    <Field name={fieldName}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { setFieldTouched, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta
      }: FieldProps): JSX.Element => {
        return (
          <div>
            <FormLabel label={label} />
            <TextArea onFocus={() => setFieldTouched(fieldName, false)} style={{ height: 250, borderColor: meta.error && meta.touched ? '#dc3545' : undefined }} {...field} {...rest} />
            <div className={meta.touched && meta.error ? 'field-error-show' : 'field-error-hide'}>{meta.error}</div>
          </div>
        );
      }}
    </Field>
  );
};
