import { CaretRightOutlined } from '@ant-design/icons';
import { Checkbox, Col, List, Modal, Row, Space, Spin, Typography } from 'antd';
import { useFormikContext } from 'formik';
import { SparingRequestPayload } from 'models/SparingRequests';
import { EditUserPayload } from 'models/Users';
import { FC, Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useUpdateUserMutation } from 'redux/services/cookieMonster/cookieMonsterApi';
import { setUser } from 'redux/slices/appSlice';
import { useAppSelector } from 'redux/store';
import { Loader } from './Loader';

const compliance = {
  text: 'By clicking Confirm, you agree to receive text messages from MDSi to the phone number you provided. Messages will include alerts and updates. Unsubscribe: You can reply STOP at any time to cancel. Assistance: Reply HELP for help. Fees: Message and data rates may apply.',
  terms: ['Unsubscribe: You can reply STOP at any time to cancel.', 'Assistance: Reply HELP for help.', 'Fees: Message and data rates may apply.']
};

export const ComplianceButton: FC = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const { user } = useAppSelector((state) => state.app);
  const { values, setFieldValue } = useFormikContext<SparingRequestPayload>();
  const dispatch = useDispatch();

  const isOptedIn = (user && user['https://acuity.mdsiinc.com/user/user_metadata'].enableSparingSMSNotifications) ?? false;

  const [updateUser, { isLoading }] = useUpdateUserMutation();

  const handleUpdateUser = async (): Promise<void> => {
    try {
      const payload: EditUserPayload = {
        ...user,
        enableSparingSMSNotifications: true,
        sparingNotificationsComplianceText: compliance.text,
        enableStandardOrderVerificationSMSNotifications: (user && user['https://acuity.mdsiinc.com/user/user_metadata'].enableStandardOrderVerificationSMSNotifications) ?? false,
        enableStandardOrderShipmentSMSNotifications: (user && user['https://acuity.mdsiinc.com/user/user_metadata'].enableStandardOrderShipmentSMSNotifications) ?? false,
        standardOrderVerificationComplianceText: (user && user['https://acuity.mdsiinc.com/user/user_metadata'].standardOrderVerificationComplianceText) ?? '',
        standardOrderShipmentNotificationsComplianceText: (user && user['https://acuity.mdsiinc.com/user/user_metadata'].standardOrderShipmentNotificationsComplianceText) ?? ''
      };

      const { user_metadata } = await updateUser({ userId: user?.sub as string, payload }).unwrap();

      dispatch(
        setUser({
          ...user,
          ...{
            ['https://acuity.mdsiinc.com/user/user_metadata']: user_metadata
          }
        })
      );
      setFieldValue('isUserOptedIn', true);
      setVisible(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Fragment>
      <Row gutter={[5, 0]} align="middle">
        <Col>
          <Typography.Text>Sms Opt In</Typography.Text>
        </Col>
        <Col>
          <Checkbox
            checked={values.isUserOptedIn}
            onChange={(): void => {
              if (!isOptedIn) {
                setVisible(true);
              } else {
                setFieldValue('isUserOptedIn', !values.isUserOptedIn);
              }
            }}
          />
        </Col>
      </Row>
      <Modal open={visible} width={500} okText="Confirm" onCancel={(): void => setVisible(false)} title="A2P 10LP Compliance" onOk={handleUpdateUser}>
        <Spin spinning={isLoading} indicator={<Loader medium />}>
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <Typography.Text>{compliance.text}</Typography.Text>
            </Col>
            <Col span={24}>
              <List
                size="small"
                dataSource={compliance.terms}
                renderItem={(item): JSX.Element => {
                  return (
                    <Space>
                      <CaretRightOutlined />
                      <Typography.Text>{item}</Typography.Text>
                    </Space>
                  );
                }}
              />
            </Col>
          </Row>
        </Spin>
      </Modal>
    </Fragment>
  );
};
