import { Button, Card, Col, Empty, Row, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useFormikContext } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { SparingRequestPayload, SublinePayload } from 'models/SparingRequests';
import { useNavigate, useParams } from 'react-router-dom';
import { colors, toRgba } from 'styles/colors';
const { Title } = Typography;

const styles: InlineStylesModel = {
  header: {
    backgroundColor: toRgba(colors.royalBlueLight, 0.4)
  },
  table: {
    width: '100%'
  }
};

export const ProductsContent = (): JSX.Element => {
  const { values } = useFormikContext<SparingRequestPayload>();
  const { id } = useParams();
  const nav = useNavigate();

  const handleGetTotalQuantity = (allInventoryTotals: SublinePayload[]): number => {
    if (!allInventoryTotals) return 0;
    if (allInventoryTotals?.length === 0) return 0;
    else {
      const sumTotal = (arr: SublinePayload[]): number => arr.reduce((sum, { quantity }) => sum + (quantity ?? 0), 0);
      const totalInventory = sumTotal(allInventoryTotals);

      return totalInventory;
    }
  };

  const numberFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal'
  });

  const columns: ColumnsType<SparingRequestPayload['lineItems'][number]> = [
    {
      title: 'Line Number',
      dataIndex: 'lineNumber',
      render: (text: string, record): JSX.Element => {
        return <div>{values.lineItems.findIndex((line) => line === record) + 1}</div>;
      }
    },
    {
      title: 'Product Number',
      dataIndex: 'productNumber',
      render: (text: string, record): JSX.Element => {
        return <div>{record.productNumber}</div>;
      }
    },
    {
      title: 'SLA',
      render: (_, record): JSX.Element => {
        const sla = record.subLines
          ?.map((subline) => {
            if (subline.serviceLevelAgreement.toLowerCase() === 'NextBusinessDay'.toLowerCase()) return 'NBD';
            if (subline.serviceLevelAgreement.toLowerCase() === 'NextFlightOut'.toLowerCase()) return 'NFO';
            if (subline.serviceLevelAgreement.toLowerCase() === 'Rush'.toLowerCase()) return 'RUSH';
            if (subline.serviceLevelAgreement.toLowerCase() === 'WillCall'.toLowerCase()) return 'WILL CALL';

            return subline.serviceLevelAgreement;
          })
          ?.join(', ');

        return <div>{sla}</div>;
      }
    },
    {
      title: 'Quantity Ordered',
      dataIndex: 'qty',
      align: 'right',
      render: (text: string, record): JSX.Element => {
        return <div style={styles.number}>{numberFormatter.format(Number(record.quantity))}</div>;
      }
    },
    {
      title: 'SLA Quantity',
      dataIndex: 'qty',
      align: 'right',
      render: (text: string, record): JSX.Element => {
        const isValid = handleGetTotalQuantity(record.subLines) === record.quantity;

        return <div style={{ color: !isValid ? 'red' : 'green' }}>{numberFormatter.format(Number(handleGetTotalQuantity(record.subLines)))}</div>;
      }
    }
  ];

  const getTotalProducts = (): number => {
    let totalProducts = 0;

    for (const product of values.lineItems) {
      totalProducts += product.quantity;
    }

    return totalProducts;
  };

  return (
    <Card
      headStyle={styles.header}
      style={{ height: '100%' }}
      title={
        <Title level={4} style={{ fontWeight: 'normal' }}>
          Products
        </Title>
      }
      extra={
        <Title level={4} style={{ margin: 0, fontWeight: 'normal' }}>
          Total Products: {getTotalProducts()}
        </Title>
      }>
      <Row gutter={[8, 8]}>
        {!values?.lineItems || !values?.lineItems?.length ? (
          <Col span={24} style={{ alignItems: 'center', textAlign: 'center', height: '100%' }}>
            <Empty description="No products selected" />
            {values.shippingLocationId && <Button onClick={(): void => (id ? nav(`/edit/${id}/products`) : nav('/create/products'))}>Select A Product</Button>}
          </Col>
        ) : (
          <Table columns={columns} dataSource={values?.lineItems} style={styles.table} pagination={false} />
        )}
      </Row>
    </Card>
  );
};
