import { Button, Card, Col, Form, Row, Space, Typography } from 'antd';
import Title from 'antd/lib/typography/Title';
import { SwitchInputMock } from 'components/Create/FormItems/SwitchInputMock';
import { TextInputMock } from 'components/Create/FormItems/TextInputMock';
import { colors, toRgba } from 'styles/colors';

export const DefectiveReturnsHeader = (): JSX.Element => {
  return (
    <Card
      headStyle={{ backgroundColor: toRgba(colors.illuminatingEmerald, 0.4) }}
      title={
        <Row justify="space-between">
          <Col>
            <Title level={4} style={{ fontWeight: 'normal' }}>
              Defective Return Responsible Party
            </Title>
          </Col>
          <Col>
            <Button>Generate Waybill</Button>
          </Col>
        </Row>
      }
      style={{ height: '100%' }}>
      <Row gutter={[0, 10]}>
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Space style={{ width: '100%' }} direction="vertical">
                <Form layout="vertical">
                  <TextInputMock style={{ marginBottom: 10 }} label={'Contact Name'} />
                  <TextInputMock style={{ marginBottom: 10 }} label={'Contact Phone Number'} />
                  <TextInputMock style={{ marginBottom: 10 }} label={'Contact Email'} />
                </Form>
              </Space>
              <Space direction="vertical">
                <Row>
                  <Col style={{ padding: 0, margin: 0, marginBottom: 10 }}>
                    <Typography.Text>How to receive waybill:</Typography.Text>
                  </Col>
                </Row>
                {/* <Radio.Group
                  defaultValue={'email'}
                  optionType="button"
                  buttonStyle="solid"
                  options={[
                    { label: 'Email', value: 'email' },
                    { label: 'SMS', value: 'sms' }
                  ]}
                /> */}
                <Row>
                  <Space direction="vertical" size={5}>
                    <SwitchInputMock label="Email" />
                    <SwitchInputMock label="SMS" />
                  </Space>
                </Row>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
