import { Button, Col, Row, Space, Typography } from 'antd';
import { ProductNumberBulkSearch } from 'components/common/ProductNumberBulkSearch';
import { useFormikContext } from 'formik';
import { useBulkSearch } from 'hooks/useBulkSearch';
import { DeploymentRequestLabels, DeploymentRequestPayload } from 'models/DeploymentRequest';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useGetXrefConfigQuery } from 'redux/services/rudyCadabby/rudyCadabbyApi';
import { useAppSelector } from 'redux/store';
import { colors, toRgba } from 'styles/colors';
import { CategoryFilter, ManufacturerFilter } from './components';
import { AlternateIdFilter } from './components/AlternateIdFilter';
import { ProductDescriptionFilter } from './components/ProductDescriptionFilter';
import { ProductNumberFilter } from './components/ProductNumberFilter';
const { Text, Title } = Typography;

const styles: InlineStylesModel = {
  container: {
    backgroundColor: toRgba(colors.romanSilver, 0.4),
    width: '100%',
    padding: 24
  },
  title: {
    margin: 0,
    fontWeight: 'normal'
  },
  textColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  totalStyle: {
    fontSize: '20px',
    textAlign: 'center'
  },
  nextButton: {
    borderRadius: '5px',
    textAlign: 'right'
  }
};
const FilterGroup = (): JSX.Element => {
  const { data } = useGetXrefConfigQuery();
  const { bulkSearchResults } = useAppSelector((state) => state.app);
  const { setFieldValue, values } = useFormikContext<DeploymentRequestPayload>();
  const activeAlternateID = data?.isAlternateItemIdInUse;
  const alternateIdName = data?.alternateItemIdLabel;
  const filterSpanSize = activeAlternateID ? 8 : 5;

  const { clearValues } = useBulkSearch();

  return (
    <Row gutter={[10, 15]}>
      <Col>
        <ProductNumberFilter />
      </Col>

      <Col>
        <AlternateIdFilter alternateIdTitle={'Alt Item Id'} />
      </Col>

      <Col>
        <ProductDescriptionFilter />
      </Col>
      <Col>
        <CategoryFilter />
      </Col>
      <Col>
        <ManufacturerFilter />
      </Col>
      <Col>
        <Row justify="end">
          <Space>
            {!!bulkSearchResults?.length && <Button onClick={clearValues}>Reset</Button>}
            <ProductNumberBulkSearch />
            {/* <FavoriteListSettings /> */}
          </Space>
        </Row>
      </Col>
    </Row>
  );
};

export const HeaderFilters = (): JSX.Element => {
  const { values } = useFormikContext<DeploymentRequestPayload>();
  const itemCount = values.lineItems?.map((product) => product.quantity).reduce((previousQuantity, currentQuantity) => previousQuantity + currentQuantity, 0);

  const { productsView } = useAppSelector((state) => state.form);

  return (
    <div style={styles.container}>
      <Row justify={'space-between'}>
        <Title level={4} style={styles.title}>
          {DeploymentRequestLabels.products} {productsView === 'grid' ? 'Search' : 'Cart'}
        </Title>
        {productsView === 'grid' && <FilterGroup />}
        {productsView !== 'grid' && (
          <Row>
            <Col style={styles.totalStyle}>Total Items: {itemCount}</Col>
          </Row>
        )}
      </Row>
    </div>
  );
};
