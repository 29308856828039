import { Card, Col, Row, Typography } from 'antd';
import { ComplianceButton } from 'components/common/ComplianceButton';
import { TextInput } from 'components/Create/FormItems';
import { SubscribeInput, SubscriberList } from 'components/Create/MoreDetails';
import { ContactInfoSwitch } from 'components/Create/ShippingDetails';
import { DeploymentRequestLabels } from 'models/DeploymentRequest';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { colors, toRgba } from 'styles/colors';
const { Title } = Typography;

const styles: InlineStylesModel = {
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: toRgba(colors.romanSilver, 0.4),
    justifyContent: 'space-between',
    width: '100%',
    padding: 24,
    borderBottom: '2px solid rgba(0,0,0,.1)'
  },
  headerTitle: {
    margin: 0,
    fontWeight: 'normal'
  },
  container: {
    paddingBottom: '20px'
  },
  nextButton: {
    borderRadius: '5px',
    marginBlock: 'auto'
  }
};

export const ContactInfoPage = (): JSX.Element => {
  return (
    <Row gutter={[12, 12]}>
      <Col span={12}>
        <Card
          headStyle={{ backgroundColor: toRgba(colors.robinEggBlue, 0.4) }}
          title={
            <Row justify="space-between">
              <Col>
                <Title level={4} style={{ fontWeight: 'normal', padding: 0, margin: 0 }}>
                  Contact Information
                </Title>
              </Col>
              <Col>
                <ContactInfoSwitch />
              </Col>
            </Row>
          }
          style={{ height: '100%' }}>
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <TextInput fieldName="contactName" style={{ marginBottom: 10 }} label={DeploymentRequestLabels.contactName} />
                  <TextInput fieldName="contactPhoneNumber" style={{ marginBottom: 10 }} label={DeploymentRequestLabels.contactPhone} />
                  <TextInput fieldName="contactEmail" style={{ marginBottom: 10 }} label={DeploymentRequestLabels.contactEmail} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={12}>
        <Card
          headStyle={{ backgroundColor: toRgba(colors.orangeWeb, 0.4) }}
          title={
            <Row justify="space-between" align={'bottom'}>
              <Col>
                <Title level={4} style={{ fontWeight: 'normal', padding: 0, margin: 0 }}>
                  Notifications
                </Title>
              </Col>
              <Col>
                <ComplianceButton />
              </Col>
            </Row>
          }
          style={{ height: '100%' }}>
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <SubscribeInput />
              <SubscriberList />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
