import { Col } from 'antd';
import { Country, State } from 'country-state-city';
import { AddressLabels } from 'models/Address';
import { DefaultOptionType } from 'rc-cascader';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCountryAlpha2CodeEquals, setSubdivisionLocalCodeEquals } from 'redux/services/chuckieSue/addressesParams';
import { useAppSelector } from 'redux/store';
import { FancySelect } from '../Inputs';

export const CountryStateInputs: React.FC = () => {
  const { subdivisionLocalCodeEquals, countryAlpha2CodeEquals } = useAppSelector((state) => state.chuckieSueAddressesParams);
  const { fullOutageAddress } = useAppSelector((state) => state.form);
  const { acuityContext } = useAppSelector((state) => state.app);

  const dispatch = useDispatch();

  const countries = Country.getAllCountries();
  const states = countryAlpha2CodeEquals ? State.getStatesOfCountry(countryAlpha2CodeEquals) : [];

  const countryNames = countries.map((country) => ({ label: country.name, value: country.isoCode }));

  const stateOptions: DefaultOptionType[] | undefined = states.map((state) => ({ label: state.name, value: state.isoCode }));

  const findInstallCountry = fullOutageAddress ? countries?.find((country) => country.isoCode === fullOutageAddress.countryAlpha2Code) : undefined;

  useEffect(() => {
    if (findInstallCountry && acuityContext?.configuration?.addressDetails.displayAddressesByDefault) {
      dispatch(setCountryAlpha2CodeEquals(findInstallCountry.isoCode));

      return;
    }
    if (countries && acuityContext?.configuration?.addressDetails.displayAddressesByDefault) {
      dispatch(setCountryAlpha2CodeEquals('US'));
      dispatch(setSubdivisionLocalCodeEquals(undefined));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleStateChange = (value: string): void => {
    dispatch(setSubdivisionLocalCodeEquals(value));
  };

  const handleCountryChange = (value: string): void => {
    dispatch(setCountryAlpha2CodeEquals(value));
    dispatch(setSubdivisionLocalCodeEquals(undefined));
  };

  return (
    <>
      <Col span={6}>
        <FancySelect
          initialTop={!!countryAlpha2CodeEquals}
          placeholder="Country"
          style={{ margin: 0 }}
          defaultActiveFirstOption={true}
          defaultOpen={false}
          value={countryAlpha2CodeEquals}
          onChange={handleCountryChange}
          showSearch
          options={countryNames ? countryNames : undefined}
        />
      </Col>
      <Col span={6}>
        <FancySelect
          disabled={!countryAlpha2CodeEquals}
          showSearch
          placeholder={AddressLabels.subdivisionLocalCode}
          onChange={handleStateChange}
          onDeselect={(): void => {
            dispatch(setSubdivisionLocalCodeEquals(undefined));
          }}
          options={stateOptions}
          value={subdivisionLocalCodeEquals}
        />
      </Col>
    </>
  );
};
