import { Col, Row, Space, Typography } from 'antd';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { SparingRequest } from 'models/SparingRequests';
import moment from 'moment';
import { FC } from 'react';
const styles: InlineStylesModel = {
  headerTitle: {
    fontWeight: 'bold',
    color: '#2F5596'
  }
};

type Props = {
  values: SparingRequest;
};

export const DeploymentRequestInfoPosted: FC<Props> = ({ values }) => {
  const outageAddress = `${values.outageLocation.name}: ${values.outageLocation.code}: ${values.outageLocation.street1}, ${values.outageLocation.city}, ${
    values.outageLocation?.subdivisionLocalCode || ''
  }, ${values.outageLocation.country || ''} ${values.outageLocation.postalCode}`;

  return (
    <div style={{ marginBottom: 20 }}>
      <Row align="middle" gutter={[15, 15]} style={{ marginBottom: 10 }}>
        <Col>
          <Space>
            <Typography.Text style={styles.headerTitle}>Sparing Request Number: </Typography.Text>
            <Typography.Text>{values.requestNumber}</Typography.Text>
          </Space>
        </Col>
        {values.customerReferenceNumber && (
          <Col>
            <Space>
              <Typography.Text style={styles.headerTitle}>Primary Reference Number: </Typography.Text>
              <Typography.Text>{values.customerReferenceNumber}</Typography.Text>
            </Space>
          </Col>
        )}
        {values.customerPurchaseOrderNumber && (
          <Col>
            <Space>
              <Typography.Text style={styles.headerTitle}>Secondary Reference Number: </Typography.Text>
              <Typography.Text>{values.customerPurchaseOrderNumber}</Typography.Text>
            </Space>
          </Col>
        )}
        {values.sparingRequestType.name && (
          <Col>
            <Space>
              <Typography.Text style={styles.headerTitle}>Spare Type: </Typography.Text>
              <Typography.Text>{values.sparingRequestType.name}</Typography.Text>
            </Space>
          </Col>
        )}
        {values.status && (
          <Col>
            <Space>
              <Typography.Text style={styles.headerTitle}>Status: </Typography.Text>
              <Typography.Text>{values.status === 'Posted' ? 'Open' : values.status}</Typography.Text>
            </Space>
          </Col>
        )}
        {values.createdDateTime && (
          <Col>
            <Space>
              <Typography.Text style={styles.headerTitle}>Created Date: </Typography.Text>
              <Typography.Text>{moment(values.createdDateTime).format('MM/DD/YYYY')}</Typography.Text>
            </Space>
          </Col>
        )}
        {values.createdByFullName && (
          <Col>
            <Space>
              <Typography.Text style={styles.headerTitle}>Requester: </Typography.Text>
              <Typography.Text>{values.createdByFullName}</Typography.Text>
            </Space>
          </Col>
        )}
      </Row>
      <Row align="middle" gutter={[0, 8]} style={{ marginBottom: 8 }}>
        {outageAddress && (
          <Col span={24} xxl={24}>
            <Space>
              <Typography.Text style={styles.headerTitle}>Outage Address: </Typography.Text>
              <Typography.Text ellipsis={{ tooltip: '' }} style={{ textTransform: 'capitalize' }}>
                {outageAddress}
              </Typography.Text>
            </Space>
          </Col>
        )}
      </Row>
    </div>
  );
};
