import { Modal, ModalProps, Space, Spin, notification } from 'antd';
import { FieldInput } from 'components/Create/FormItems/FieldInput';
import { FieldInputDatePicker } from 'components/Create/FormItems/FieldInputDatePicker.tsx';
import { FieldInputTextArea } from 'components/Create/FormItems/FieldInputTextArea';
import { ColoredCard } from 'components/common/ColoredCard';
import { Loader } from 'components/common/Loader';
import { FormikProvider, useFormik } from 'formik';
import { QueryErrorModel } from 'models/ErrorModel';
import { SparingOrder } from 'models/PostedSpareOrder';
import { SparingOrderTrackingPayload } from 'models/SparingRequests';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useUpdateSparingOrderTrackingInformationMutation } from 'redux/services/twittlebugs/sparingRequestsApi';

type Props = ModalProps & {
  sparingOrder?: SparingOrder;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<string>>;
};

export const UpdateTrackingInfoModal: FC<Props> = ({ sparingOrder, isOpen, setIsOpen, ...rest }) => {
  const { id } = useParams();

  const [updateTrackingInfo] = useUpdateSparingOrderTrackingInformationMutation();
  const formik = useFormik<SparingOrderTrackingPayload>({
    enableReinitialize: true,
    initialValues: {
      deliveryContactName: sparingOrder?.trackingInformation?.deliveryContactName ?? '',
      deliveryDate: sparingOrder?.trackingInformation?.deliveryDate ? sparingOrder?.trackingInformation?.deliveryDate : '',
      trackingNumbers: sparingOrder?.trackingInformation?.trackingNumbers ?? [],
      shipMethod: sparingOrder?.trackingInformation?.shipMethod ?? ''
    },
    onSubmit: async (values) => {
      if (!id || !sparingOrder?.id) {
        notification.error({
          message: 'Error',
          description: 'An error occured, the team has been notified.',
          className: 'custom-class',
          style: {
            width: 600
          },
          duration: 10
        });

        return;
      }

      try {
        const response = await updateTrackingInfo({ sparingRequestId: id, sparingOrderid: sparingOrder.id, body: values }).unwrap();

        notification.success({
          message: 'Success',
          description: `Tracking Information for ${response.salesOrderNumber} updated successfully`,
          style: {
            width: 600
          },
          duration: 10
        });
        setIsOpen('');
        formik.resetForm();
      } catch (e) {
        console.log(e);
        const error = e as QueryErrorModel;
        const errorMessage = error?.data?.errorMessage ? error?.data?.errorMessage : error.data ? (error?.data as string) : 'An error occured, the team has been notified.';

        notification.error({
          message: 'Error',
          description: errorMessage,
          className: 'custom-class',
          style: {
            width: 600
          },
          duration: 10
        });
      }
    }
  });

  const handleSubmit = (): void => {
    formik.submitForm();
  };

  const handleCancel = (): void => {
    formik.resetForm();
    setIsOpen('');
  };

  return (
    <Modal open={isOpen} onOk={handleSubmit} onCancel={handleCancel} closable={false} {...rest}>
      <Spin spinning={formik.isSubmitting} indicator={<Loader loadingMessage="Updating Order" />}>
        <ColoredCard title={`Tracking Info: ${sparingOrder?.salesOrderNumber}`} color="rgb(206, 213, 242)">
          <FormikProvider value={formik}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <FieldInput label="Point of Delivery Contact" fieldName="deliveryContactName" />
              <FieldInputDatePicker label="Delivery Date (UTC)" fieldName="deliveryDate" />
              <FieldInputTextArea
                value={formik?.values?.trackingNumbers?.join('\n')}
                onChange={(e) => formik.setFieldValue('trackingNumbers', e.target.value.split('\n'))}
                label="Tracking Numbers"
                fieldName="trackingNumbers"
              />
            </Space>
          </FormikProvider>
        </ColoredCard>
      </Spin>
    </Modal>
  );
};
