import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Space, Table, Tooltip, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { PartNumberToolTip } from 'components/common/PartNumberToolTip';
import { PostedSparingRequestLineItem } from 'models/SparingRequests';
import { FC } from 'react';

type Props = {
  lineItems: PostedSparingRequestLineItem[];
};

export const PartInfoTable: FC<Props> = ({ lineItems }) => {
  const cols: ColumnType<PostedSparingRequestLineItem>[] = [
    {
      title: 'Product Number',
      dataIndex: 'productNumber',
      key: 'productNumber',
      render: (_, record): JSX.Element => {
        return (
          <Space>
            <Tooltip color="white" title={<PartNumberToolTip record={record} />}>
              <InfoCircleOutlined style={{ color: 'rgb(39, 134, 250)' }} />
            </Tooltip>
            <Typography.Text>{record.productNumber}</Typography.Text>
          </Space>
        );
      }
    },
    {
      title: 'Posted',
      dataIndex: 'isPosted',
      key: 'isPosted',
      align: 'center',
      render: (val: boolean): JSX.Element => (val ? <CheckCircleOutlined style={{ color: 'green', fontSize: 16 }} /> : <CloseCircleOutlined style={{ color: 'red', fontSize: 16 }} />)
    },
    {
      title: 'Owner',
      dataIndex: 'ownerId',
      key: 'ownerId'
    },
    {
      title: 'Condition',
      dataIndex: 'conditionId',
      key: 'conditionId'
    },
    {
      title: 'Disposition',
      dataIndex: 'dispositionId',
      key: 'dispositionId'
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'right'
    }
  ];

  return <Table pagination={false} dataSource={lineItems} size="small" columns={cols} />;
};
