import { Checkbox, CheckboxProps, Space, Typography } from 'antd';
interface Props extends CheckboxProps {
  label: string;
  color?: string;
}

export const SwitchInputMock = ({ label, ...rest }: Props): JSX.Element => {
  return (
    <Space direction="vertical" size={8}>
      <Typography.Text>{label}</Typography.Text>
      <Checkbox {...rest} />
    </Space>
  );
};
