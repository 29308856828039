// import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Form, Modal, Spin, notification } from 'antd';
import { ActionControls, StepControls } from 'components/Create/common';
import { DeploymentRequestInfo } from 'components/Create/common/DeploymentRequestInfo';
import { SparingRequestConfirmation } from 'components/Create/common/SparingtRequestConfirmation.tsx';
import { Loader } from 'components/common/Loader';
import { PostedLineItemsModal } from 'components/common/PostedLineItemsModal';
import { FormikProvider, useFormik } from 'formik';
import { QueryErrorModel } from 'models/ErrorModel';
import { SparingRequest, SparingRequestPayload } from 'models/SparingRequests';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAddUserToSmsNotificationsMutation, useCreateAttachmentMutation, useCreateSparingRequestMutation, usePostSparingRequestMutation } from 'redux/services/twittlebugs/sparingRequestsApi';
import { resetAttachments } from 'redux/slices/attachmentsSlice';
import { setFullOutageAddress, setFullShippingAddress, setIsExit, setIsPost } from 'redux/slices/formSlice';
import { useAppSelector } from 'redux/store';

export const CreateDeploymentRequestLayout = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { user, postRequestLoadingMessage } = useAppSelector((state) => state.app);
  const { isExit, isPost } = useAppSelector((state) => state.form);
  const { attachments } = useAppSelector((state) => state.attachments);
  const nav = useNavigate();

  const [createAttachment] = useCreateAttachmentMutation();
  const [addUserToSmsNotifications] = useAddUserToSmsNotificationsMutation();
  const [createSparing] = useCreateSparingRequestMutation();
  const [postSparingRequest, { data: postedData, isSuccess }] = usePostSparingRequestMutation();
  const [loadingMessage, setLoadingMessage] = useState<string | undefined>(undefined);

  const dispatch = useDispatch();

  const [createSparingResponse, setCreateSparingResponse] = useState<SparingRequest | undefined>(undefined);

  const userNumber = user?.['https://acuity.mdsiinc.com/user/user_metadata']?.phone_number;

  const formik = useFormik<SparingRequestPayload>({
    enableReinitialize: true,
    initialValues: {
      requestNumber: '',
      customerReferenceNumber: '',
      customerPurchaseOrderNumber: '',
      sparingRequestTypeId: '',
      shippingLocationId: '',
      lineItems: [],
      outageLocationId: '',
      contactEmail: user?.email ?? '',
      contactName: user?.name ?? '',
      contactPhoneNumber: userNumber ?? '',
      requestedDeliveryDate: '',
      notificationEmails: user && user.email ? [user.email] : [],
      notes: '',
      isLabConfigurationRequired: false,
      isUserOptedIn: false
    },
    onSubmit: async (values) => {
      setLoadingMessage('Creating sparing request...');
      const payload = {
        ...values,
        lineItems: values.lineItems.map((item, idx) => ({
          productNumber: item.productNumber,
          lineNumber: idx + 1,
          quantity: item.quantity,
          subLines: item.subLines
        }))
      };

      let resp;

      try {
        const response = await createSparing(payload).unwrap();

        if (values.isUserOptedIn) {
          await addUserToSmsNotifications({ id: response.id, payload: {} }).unwrap();
        }

        resp = response;

        if (attachments.length) {
          setLoadingMessage('Creating attachments...');
          for await (const attachment of attachments) {
            const formData = new FormData();

            formData.append(`${attachment.name}`, attachment);
            await createAttachment({ file: formData, id: response.id }).unwrap();
          }
        }
        if (isPost) {
          setLoadingMessage(undefined);
          await postSparingRequest({ sparingResponse: response, sparingRequestId: response.id }).unwrap();

          dispatch(setIsPost(false));
        } else setCreateSparingResponse(response);
        dispatch(resetAttachments());
      } catch (e) {
        if (resp?.id) nav(`/edit/${resp.id}`);
        setLoadingMessage(undefined);
        console.error(e, 'error');

        const error = e as QueryErrorModel;
        const errorMessage = error?.data?.errorMessage ? error?.data?.errorMessage : error.data ? (error?.data as string) : 'An error occured, the team has been notified.';

        dispatch(setIsPost(false));

        notification.error({
          message: 'Error',
          description: errorMessage,
          className: 'custom-class',
          style: {
            width: 600
          },
          duration: 5
        });
      }
    }
  });

  const handleClose = (): void => {
    if (isExit) nav('/');
    else nav(`/edit/${createSparingResponse?.id}`);
    setCreateSparingResponse(undefined);
    dispatch(setIsExit(false));
  };

  /* ******************** Effects ******************** */
  useEffect(() => {
    return () => {
      dispatch(setFullOutageAddress(null));
      dispatch(setFullShippingAddress(null));
      dispatch(resetAttachments());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if (requestConfigError) {
  //   <NoConfigType messageContent="is not configured for Sparing Requests. Please contact MDSi support." />;
  // }

  // if (requestTypeError || (requestTypesData && requestTypesData.totalCount === 0)) {
  //   return <NoConfigType messageContent="has no deployment request types created. Please contact MDSi support." />;
  // }

  return (
    <>
      <FormikProvider value={formik}>
        <Spin indicator={<Loader loadingMessage={postRequestLoadingMessage ?? loadingMessage} />} spinning={formik.isSubmitting}>
          <Form layout="vertical">
            <ActionControls />
            <DeploymentRequestInfo />
            <StepControls />
            <Outlet />
          </Form>
        </Spin>
      </FormikProvider>
      <Modal
        onOk={handleClose}
        open={createSparingResponse !== undefined}
        cancelButtonProps={{ style: { display: 'none' } }}
        onCancel={handleClose}
        closeIcon={undefined}
        bodyStyle={{ background: 'linear-gradient(to right bottom, #979797, #acacac, #c1c1c1, #d7d7d7, #ededed)', padding: 0, height: 400, position: 'relative', borderRadius: 5 }}>
        <SparingRequestConfirmation confirmationInfo={createSparingResponse} />
      </Modal>
      <PostedLineItemsModal open={isSuccess} submittedSparingRequest={postedData?.data} />
    </>
  );
};
