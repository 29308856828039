import { Col, Divider, Modal, ModalProps, Row, Typography } from 'antd';
import { PostedSpareOrder } from 'models/PostedSpareOrder';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { sparingRequestApi } from 'redux/services/twittlebugs';
import '../../styles/waveAnimation.css';

type Props = ModalProps & {
  submittedSparingRequest?: PostedSpareOrder;
};

export const PostedLineItemsModal: FC<Props> = ({ submittedSparingRequest, ...rest }) => {
  const nav = useNavigate();
  const dispatch = useDispatch();

  return (
    <Modal
      width={1000}
      bodyStyle={{
        background: 'linear-gradient(to right bottom, #979797, #acacac, #c1c1c1, #d7d7d7, #ededed)',
        padding: 0,
        position: 'relative',
        borderRadius: 5
      }}
      onOk={(): void => {
        dispatch(sparingRequestApi.util.invalidateTags(['Orders']));
        nav(`/posted/${submittedSparingRequest?.id}`);
      }}
      cancelButtonProps={{ style: { display: 'none' } }}
      okText="Continue"
      closable={false}
      {...rest}>
      <Row>
        <Typography.Title level={3} style={{ margin: 20 }}>
          Request Successfully Submitted: {submittedSparingRequest?.requestNumber}
        </Typography.Title>
      </Row>
      <Divider style={{ marginTop: 0, marginBottom: 18 }} />
      <Row style={{ marginLeft: 20 }}>
        <Col span={6}>
          <Typography.Text>Sparing Order</Typography.Text>
        </Col>
        <Col span={2}>
          <Typography.Text>Status</Typography.Text>
        </Col>
        <Col span={16}>
          <Typography.Text>Message</Typography.Text>
        </Col>
      </Row>

      <Row style={{ maxHeight: 400, overflowY: 'auto', overflowX: 'hidden' }}>
        {submittedSparingRequest?.processLogs?.map((line, idx) => {
          return (
            <Row
              key={idx}
              justify="space-between"
              align="middle"
              style={{
                borderTop: '1px solid rgba(0,0,0,.1)',
                background: '#ffffff70',
                padding: '5px 25px',
                borderRadius: 10,
                margin: '10px 10px 0px 10px',
                width: '100%',
                minHeight: 55
              }}>
              <Col span={6}>{submittedSparingRequest.sparingOrders.find((order) => line.message.includes(order.salesOrderNumber))?.salesOrderNumber}</Col>
              <Col span={2} style={{ justifyContent: 'center' }}>
                {line.status !== 'Failure' ? (
                  <div style={{ width: 20, marginLeft: 10, marginTop: 5 }}>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                      <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="10" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                      <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                    </svg>
                  </div>
                ) : (
                  <div style={{ width: 20, marginLeft: 10, marginTop: 5 }}>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                      <circle className="path circle" fill="none" stroke="#D06079" strokeWidth="7" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                      <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
                      <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
                    </svg>
                  </div>
                )}
              </Col>
              <Col span={16}>{line.message}</Col>
            </Row>
          );
        })}
      </Row>
      <Divider style={{ margin: '18px 0' }} />
      <Col style={{ bottom: 0, width: '100%', marginTop: 50 }}>
        <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g className="parallax">
            <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
            <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
            <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
            <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
          </g>
        </svg>
      </Col>
    </Modal>
  );
};
