import { Card, Col, Row, Spin, Typography } from 'antd';
import { FieldInput } from 'components/Create/FormItems/FieldInput';
import { FieldInputSelect } from 'components/Create/FormItems/FieldInputSelect';
import { Loader } from 'components/common/Loader';

import { useFormikContext } from 'formik';
import { SparingRequestPayload } from 'models/SparingRequests';
import React, { useEffect } from 'react';
import { useGetSparingRequestConfigurationTypesQuery } from 'redux/services/calvin/sparingRequestConfigTypes';
import { useAppSelector } from 'redux/store';
import { colors, toRgba } from 'styles/colors';
const { Title } = Typography;

interface Props {
  requestType: string;
}

export const RequestDetailsPage: React.FC<Props> = () => {
  const { setFieldValue } = useFormikContext<SparingRequestPayload>();

  const { acuityContext } = useAppSelector((state) => state.app);

  const { data: typesData, isLoading: isTypesLoading } = useGetSparingRequestConfigurationTypesQuery();

  useEffect(() => {
    if (typesData?.data.length === 1) setFieldValue('sparingRequestTypeId', typesData?.data[0].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typesData?.data]);

  return (
    <Row gutter={[12, 12]} style={{ height: '100%' }}>
      <Col style={{ minHeight: '100%' }} span={12}>
        <Card
          headStyle={{ backgroundColor: toRgba(colors.royalBlueLight, 0.4) }}
          title={
            <Title level={4} style={{ fontWeight: 'normal' }}>
              Customer Reference
            </Title>
          }
          style={{ height: '100%', minHeight: '100%' }}>
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <FieldInput fieldName="customerReferenceNumber" label={acuityContext?.configuration?.reference1Label || 'Primary Reference Number'} />
            </Col>
            <Col span={24}>
              <FieldInput fieldName="customerPurchaseOrderNumber" label={acuityContext?.configuration?.reference2Label || 'Secondary Reference Number'} />
            </Col>
          </Row>
        </Card>
      </Col>
      <Col style={{ minHeight: '100%' }} span={12}>
        <Card
          headStyle={{ backgroundColor: toRgba(colors.illuminatingEmerald, 0.4) }}
          title={
            <Title level={4} style={{ fontWeight: 'normal' }}>
              Sparing Order
            </Title>
          }
          bodyStyle={{ minHeight: '100%' }}
          style={{ height: '100%', minHeight: '100%' }}>
          <Spin style={{ width: '100%', height: '100%' }} indicator={<Loader medium />} spinning={isTypesLoading}>
            <Row gutter={[0, 10]}>
              <Col span={24}>
                <FieldInputSelect label="Sparing Request Type" fieldName="sparingRequestTypeId" options={typesData?.data.map((type) => ({ label: type.name, value: type.id }))} />
              </Col>
            </Row>
          </Spin>
        </Card>
      </Col>
    </Row>
  );
};
