import { Table, TableProps } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { formatChoiceStatusRaw } from 'helpers/formatChoiceStatus';
import { SparingOrder } from 'models/PostedSpareOrder';
import { TimeZoneData } from 'models/Timezone';
import moment from 'moment-timezone';
import { FC } from 'react';

type Props = TableProps<SparingOrder> & {
  timezoneData?: TimeZoneData;
};

export const SalesOrderQuickViewTable: FC<Props> = ({ timezoneData, ...rest }) => {
  const timeZone = moment.tz(timezoneData?.timeZone ?? '').format('z');

  const cols: ColumnType<SparingOrder>[] = [
    {
      title: 'Sales Order',
      dataIndex: 'salesOrderNumber',
      key: 'salesOrderNumber',
      render: (text) => <div>{text || 'Failed'}</div>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => <div>{formatChoiceStatusRaw(record)}</div>
    },
    {
      title: 'ETA (UTC)',
      dataIndex: 'actualDeliveryDate',
      key: 'actualDeliveryDate',
      render: (_, record) => <div>{record.estimatedDeliveryDate ? moment(record.estimatedDeliveryDate).utc().format('MM/DD/YYYY HH:mm') : 'TBD'}</div>
    },
    {
      title: `ETA (${timeZone})`,
      dataIndex: 'actualDeliveryDate',
      key: 'actualDeliveryDate',
      render: (_, record): JSX.Element => {
        const formattedDateDeliveryTimeZone = moment.tz(record.estimatedDeliveryDate, timezoneData?.timeZone ?? '').format('MM/DD/YYYY hh:mm A');

        return <div>{record.estimatedDeliveryDate ? formattedDateDeliveryTimeZone : 'TBD'}</div>;
      }
    }
  ];

  return <Table columns={cols} {...rest} />;
};
