import { green } from '@ant-design/colors';
import { LoadingOutlined } from '@ant-design/icons';
import { Card, Col, Row, Typography } from 'antd';

import { InlineStylesModel } from 'models/InlineStylesModel';
import { ProductPayload } from 'models/Product';
import React from 'react';
const { Text, Title } = Typography;

interface Props {
  product: ProductPayload;
  availableQuantity: number;
  isLoading: boolean;
  children: JSX.Element;
}

const styles: InlineStylesModel = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    lineHeight: 1
  },
  cardBody: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },

  cardTitle: {
    width: '250px'
  },
  productInfo: { display: 'flex', flexDirection: 'column', textAlign: 'end' },
  productDescription: {
    flex: 1,
    marginBottom: 24
  }
};

export const ProductCartCard: React.FC<Props> = ({ product, children, availableQuantity, isLoading }) => {
  /* ******************** Hooks ******************** */

  const cardTitleJSX = (
    <div style={styles.cardTitle}>
      <Title style={{ margin: 0 }} level={4}>
        {product.productName}
      </Title>
      <Text style={{ fontWeight: 'normal', fontSize: '12px' }} type="secondary">
        {product.manufacturer} | {product.category}
      </Text>
    </div>
  );

  /* ******************** Render ******************** */
  return (
    <Card style={styles.container} title={cardTitleJSX} bodyStyle={styles.cardBody}>
      <Row>
        <Col span={16}>
          <Text style={styles.productDescription}>{product.productDescription}</Text>
        </Col>
        <Col span={4}>
          <div style={styles.productInfo}>
            <Text type="success">Available: {isLoading ? <LoadingOutlined style={{ color: green.primary }} /> : availableQuantity}</Text>
          </div>
        </Col>
        <Col span={4} style={{ textAlign: 'right' }}>
          {children}
        </Col>
      </Row>
    </Card>
  );
};
