import { Divider, Space, Typography } from 'antd';
import { TimeZoneData } from 'models/Timezone';
import moment from 'moment-timezone';
import { FC } from 'react';

type Props = {
  dateTime: string;
  spaceSize?: number;
  spaceDirection?: 'vertical' | 'horizontal';
  timezoneData?: TimeZoneData;
  spaceStyle?: React.CSSProperties;
  divider?: boolean;
  utcStyle?: React.CSSProperties;
  deliveryStyle?: React.CSSProperties;
};

export const TimeZoneText: FC<Props> = ({ dateTime, spaceDirection, spaceSize, timezoneData, spaceStyle, divider, utcStyle, deliveryStyle }): JSX.Element => {
  const formattedDateUTC = moment(dateTime).utc().format('MM/DD/YYYY HH:mm');
  const formattedDateDeliveryTimeZone = moment.tz(dateTime, timezoneData?.timeZone ?? '').format('MM/DD/YYYY hh:mm A');
  const timeZone = moment.tz(timezoneData?.timeZone ?? '').format('z');
  const utc = `${formattedDateUTC} UTC`;
  const delivery = `${formattedDateDeliveryTimeZone} ${timeZone}`;

  if (!dateTime) {
    return <Typography.Text style={{ marginLeft: 5 }}>TBD</Typography.Text>;
  }

  return (
    <Space style={spaceStyle} direction={spaceDirection} size={spaceSize}>
      <Typography.Text style={utcStyle}>{utc}</Typography.Text>
      {divider && <Divider type={'vertical'} style={{ borderColor: 'black' }} />}
      {timezoneData && <Typography.Text style={deliveryStyle}>{delivery}</Typography.Text>}
    </Space>
  );
};
