import { InfoCircleOutlined } from '@ant-design/icons';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Space, Table, Tooltip, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { LocalDeliveryRow } from 'components/Create/TableComponents/LocalDeliveryRow';
import { NBDRow } from 'components/Create/TableComponents/NBDRow';
import { NFORow } from 'components/Create/TableComponents/NFORow';
import { Loader } from 'components/common/Loader';
import { PartNumberToolTip } from 'components/common/PartNumberToolTip';
import { useFormikContext } from 'formik';
import { SparingRequestLineItemPayload, SparingRequestPayload } from 'models/SparingRequests';
import { useGetSparingRequestConfigurationTypeQuery } from 'redux/services/calvin/sparingRequestConfigTypes';

export const ServiceLevelTable = (): JSX.Element => {
  const { values } = useFormikContext<SparingRequestPayload>();

  const { data, isLoading } = useGetSparingRequestConfigurationTypeQuery(values.sparingRequestTypeId ?? skipToken);

  // eslint-disable-next-line prefer-const
  let singleProductsArray: {
    quantity: number;
    alternateItemId?: string | null | undefined;
    id?: string | undefined;
    category?: string | undefined;
    manufacturer?: string | undefined;
    productDescription?: string | undefined;
    productName?: string | undefined;
    productNumber: string;
    customerStandardCost?: number | undefined;
    customerXRef?: { alternateItemId: string | null; customerStandardCost: number | null } | undefined;
  }[] = [];

  values.lineItems.forEach((line) => {
    for (let i = 0; i < line?.quantity; i++) {
      singleProductsArray.push({ ...line, quantity: 1 });
    }
  });

  const isNFODisabled = !data?.isNextFlightOutEnabled;
  const isNBDDisabled = !data?.isNextBusinessDayEnabled;
  const isLocalDisabled = !data?.isLocalDeliveryEnabled;

  const cols: ColumnType<SparingRequestLineItemPayload>[] = [
    {
      title: 'Part Number',
      dataIndex: 'productNumber',
      key: 'productNumber',
      width: '30ch',
      render: (_, record): JSX.Element => {
        return (
          <Space>
            <Tooltip color="white" title={<PartNumberToolTip record={record} />}>
              <InfoCircleOutlined style={{ color: 'rgb(39, 134, 250)' }} />
            </Tooltip>
            <Typography.Text>{record.productNumber}</Typography.Text>
          </Space>
        );
      }
    },
    {
      title: 'Alt Item Id',
      dataIndex: 'alternateItemId',
      key: 'alternateItemId',
      width: '15ch'
    },
    {
      title: 'Quantity Ordered',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'center',
      width: 150
    },
    {
      title: 'Local Delivery',
      key: 'localDelivery',
      align: 'center',

      render: (_, record, idx): JSX.Element => {
        if (isLocalDisabled) {
          return <Typography.Text disabled>This method cannot be used with the current sparing request type configuration</Typography.Text>;
        }

        return <LocalDeliveryRow record={record} idx={idx} />;
      },
      width: 360
    },
    {
      title: 'Next Business Day Qty',
      key: 'nextBusinessDay',
      align: 'center',

      render: (_, record, idx): JSX.Element => {
        if (isNBDDisabled) {
          return <Typography.Text disabled>This method cannot be used with the current sparing request type configuration</Typography.Text>;
        }

        return <NBDRow record={record} idx={idx} />;
      },
      width: 280
    },
    {
      title: 'Next Flight Out Qty',
      key: 'nextFlightOut',
      align: 'center',

      render: (_, record, idx): JSX.Element => {
        if (isNFODisabled) {
          return <Typography.Text disabled>This method cannot be used with the current sparing request type configuration</Typography.Text>;
        }

        return <NFORow record={record} idx={idx} />;
      },
      width: 280
    }
  ];

  return <Table loading={{ spinning: isLoading, indicator: <Loader /> }} dataSource={values.lineItems} columns={cols} />;
};
