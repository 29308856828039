import { Card, CardProps, Col, List, Row, Typography } from 'antd';
import Title from 'antd/lib/typography/Title';
import { TimeZoneText } from 'components/common/TimeZoneText';
import Cookies from 'js-cookie';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { TrackingInfo } from 'models/PostedSpareOrder';
import { FC } from 'react';
import { useAppSelector } from 'redux/store';

type Props = CardProps & {
  trackingInfo: TrackingInfo;
};

const styles: InlineStylesModel = {
  infoTitle: {
    fontSize: '16px',
    fontWeight: 600,
    margin: 0,
    padding: 0
  },
  infoText: {
    fontSize: '15px',
    // minHeight: '27px',
    margin: 0,
    padding: 0
  }
};
const getCookie = (): boolean => {
  const cookie = Cookies.get('isLocalTimeZone');
  const parsedCookie = cookie ? JSON.parse(cookie) : false;

  if (parsedCookie) return true;

  return false;
};

export const TrackingInformationCard: FC<Props> = ({ trackingInfo, ...rest }) => {
  const { deliveryAddressTimeZoneInfo } = useAppSelector((state) => state.form);

  return (
    <Card
      headStyle={{ backgroundColor: 'rgba(132, 133, 154, 0.4)' }}
      title={
        <Row justify="space-between">
          <Col>
            <Title level={4} style={{ fontWeight: 'normal' }}>
              Tracking Information
            </Title>
          </Col>
        </Row>
      }
      {...rest}>
      <Row>
        <Col span={12}>
          <div style={{ height: 60 }}>
            <Typography.Title level={5} style={styles.infoTitle}>
              Ship Method:
            </Typography.Title>
            <Typography.Paragraph style={styles.infoText}>{trackingInfo.shipMethod}</Typography.Paragraph>
          </div>
          <Typography.Title level={5} style={styles.infoTitle}>
            Tracking Numbers:
          </Typography.Title>
          <List
            locale={{
              emptyText: 'No tracking numbers'
            }}
            dataSource={trackingInfo.trackingNumbers}
            renderItem={(item): JSX.Element => <Row style={{ marginBottom: 5 }}>{item}</Row>}
          />
        </Col>

        <Col span={12}>
          <div style={{ height: 60 }}>
            <Typography.Title level={5} style={styles.infoTitle}>
              Proof of Delivery Contact:
            </Typography.Title>
            <Typography.Paragraph style={styles.infoText}>{trackingInfo.deliveryContactName ? trackingInfo.deliveryContactName : ''}</Typography.Paragraph>
          </div>
          <Typography.Title level={5} style={styles.infoTitle}>
            Delivery Date/Time:
          </Typography.Title>
          <Row align={'middle'} gutter={[8, 0]}>
            <Col>
              <TimeZoneText timezoneData={deliveryAddressTimeZoneInfo} dateTime={trackingInfo.deliveryDate} spaceSize={1} spaceDirection="vertical" divider={false} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
