import { Card, Col, Modal, Row, Tooltip, Typography } from 'antd';
import { useFormikContext } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { SparingRequestPayload } from 'models/SparingRequests';
import { useDispatch } from 'react-redux';
import { Address } from 'redux/services/chuckieSue/models/addresses';
import { setFullOutageAddress, setFullShippingAddress } from 'redux/slices/formSlice';
import { colors, toRgba } from 'styles/colors';
import { ReactComponent as DeliveryIcon } from '../../../../../assets/delivery.svg';
import { ReactComponent as InstallIcon } from '../../../../../assets/install.svg';
import { ReactComponent as WarehouseIcon } from '../../../../../assets/warehouse.svg';

interface Props {
  address: Address;
  addressType: 'outage' | 'shipping';
}

const styles: InlineStylesModel = {
  cardBody: {
    borderRadius: 10,
    padding: 0
  },
  customerInfo: {
    padding: '10px 60px'
  },
  title: {
    fontSize: '.8rem',
    margin: 0,
    color: 'rgba(0, 0, 0, .4)'
  },
  titleWhite: {
    fontSize: '.8rem',
    margin: 0,
    color: 'rgba(255, 255, 255, .4)'
  },
  values: {
    fontSize: 16,
    fontWeight: 600
  },
  valuesWhite: {
    color: 'rgba(255, 255, 255, .9)',
    fontSize: 16,
    fontWeight: 600
  },
  minusButton: {
    color: '#F93943',
    borderColor: '#F93943'
  },
  selectedInstallStyles: {
    transition: '.25s all ease-out',
    background: toRgba(colors.royalBlueLight, 0.6),
    boxShadow: '0 0 6px rgba(0,0,0,.4)',
    borderRadius: 10
  },
  selectedShippingStyles: {
    transition: '.25s all ease-out',
    background: toRgba(colors.illuminatingEmerald, 0.6),
    boxShadow: '0 0 6px rgba(0,0,0,.4)',
    borderRadius: 10
  },
  removeButton: {
    margin: 'auto'
  }
};

export const LocationCard = ({ address, addressType }: Props): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { setFieldValue, values } = useFormikContext<SparingRequestPayload>();
  const dispatch = useDispatch();

  /* ******************** Functions ******************** */
  const handleSetLocation = (): void => {
    if (addressType === 'outage') {
      setFieldValue('outageLocationId', address.id);
      // setFieldValue('fullInstallAddress', address);
      dispatch(
        setFullOutageAddress({
          addressType: address.addressType,
          city: address.city,
          code: address.code,
          country: address.country.fullName,
          countryAlpha2Code: address.country.alpha2Code,
          id: address.id,
          latitude: address.latitude,
          longitude: address.longitude,
          name: address.name,
          postalCode: address.postalCode,
          subdivisionLocalCode: address?.subdivision?.localCode ?? '',
          street1: address.street1,
          street2: address.street2,
          subdivision: address?.subdivision?.name ?? ''
        })
      );
    } else {
      if (values.shippingLocationId && values.lineItems.length) {
        Modal.confirm({
          okText: 'Confrim',
          title: 'If you change the shipping location, the products will be removed from the cart. Are you sure you want to continue?',
          onOk: () => {
            setFieldValue('shippingLocationId', address.id);
            dispatch(
              setFullShippingAddress({
                addressType: address.addressType,
                city: address.city,
                code: address.code,
                country: address.country.fullName,
                countryAlpha2Code: address.country.alpha2Code,
                id: address.id,
                latitude: address.latitude,
                longitude: address.longitude,
                name: address.name,
                postalCode: address.postalCode,
                subdivisionLocalCode: address?.subdivision?.localCode ?? '',
                street1: address.street1,
                street2: address.street2,
                subdivision: address?.subdivision?.name ?? ''
              })
            );
            setFieldValue('lineItems', []);
          }
        });

        return;
      }
      setFieldValue('shippingLocationId', address.id);
      dispatch(
        setFullShippingAddress({
          addressType: address.addressType,
          city: address.city,
          code: address.code,
          country: address.country.fullName,
          countryAlpha2Code: address.country.alpha2Code,
          id: address.id,
          latitude: address.latitude,
          longitude: address.longitude,
          name: address.name,
          postalCode: address.postalCode,
          subdivisionLocalCode: address?.subdivision?.localCode ?? '',
          street1: address.street1,
          street2: address.street2,
          subdivision: address?.subdivision?.name ?? ''
        })
      );
    }
  };

  const handleRemoveInstallLocation = (): void => {
    setFieldValue(addressType === 'outage' ? 'outageLocationId' : 'shippingLocationId', null);

    // setFieldValue(addressType === 'outage' ? 'outageLocationId' : 'fullShippingAddress', null);
  };

  const isSelectedCard = addressType === 'outage' ? values.outageLocationId && values.outageLocationId === address.id : values.shippingLocationId && values.shippingLocationId === address.id;

  return (
    <Card
      bordered={false}
      style={isSelectedCard ? (addressType === 'outage' ? styles.selectedInstallStyles : styles.selectedShippingStyles) : styles.cardBody}
      bodyStyle={styles.cardBody}
      hoverable
      onClick={isSelectedCard ? handleRemoveInstallLocation : handleSetLocation}>
      <Row style={{ borderRadius: 10 }}>
        <Col span={10} style={{ background: isSelectedCard ? 'rgba(40,40,40,.6)' : 'rgba(0,0,0,.2)', padding: '0px 20px', borderRadius: '10px 0 0 10px', paddingTop: '5px' }}>
          <Row gutter={[5, 0]} style={{ paddingTop: '10px' }}>
            {address.addressType.includes('Install') ? (
              <Col>
                <Tooltip title="Install" placement="top" autoAdjustOverflow={false} overlayInnerStyle={styles.tooltipStyle}>
                  <InstallIcon width={20} />
                </Tooltip>
              </Col>
            ) : null}
            {address.addressType.includes('Delivery') ? (
              <Col>
                <Tooltip title="Delivery" placement="top" autoAdjustOverflow={false} overlayInnerStyle={styles.tooltipStyle}>
                  <DeliveryIcon width={25} />
                </Tooltip>
              </Col>
            ) : null}
            {address.addressType.includes('Warehouse') ? (
              <Col>
                <Tooltip title="Warehouse" placement="top" autoAdjustOverflow={false} overlayInnerStyle={styles.tooltipStyle}>
                  <WarehouseIcon width={20} />
                </Tooltip>
              </Col>
            ) : null}
          </Row>
          <Typography.Paragraph style={isSelectedCard ? styles.titleWhite : styles.title}>
            <Tooltip title="Address Code" placement="left" autoAdjustOverflow={false} overlayInnerStyle={styles.tooltipStyle}>
              {address.code || <span>&nbsp;</span>}
            </Tooltip>
          </Typography.Paragraph>
          <Typography.Paragraph style={isSelectedCard ? styles.valuesWhite : styles.values}>
            {' '}
            <Tooltip title="Address Name" placement="left" autoAdjustOverflow={false} overlayInnerStyle={styles.tooltipStyle}>
              {address.name || <span>&nbsp;</span>}
            </Tooltip>
          </Typography.Paragraph>
        </Col>
        <Col span={14}>
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              width: '81.25px',
              height: '45.625px',
              borderLeft: isSelectedCard ? 'solid 40.625px rgba(40,40,40,.6)' : 'solid 40.625px rgba(0,0,0,.2)',
              borderBottom: 'solid 50.625px transparent',
              borderTop: 'solid 50.625px transparent'
            }}
          />
          <Col style={styles.customerInfo}>
            <Typography.Paragraph style={isSelectedCard ? styles.titleWhite : styles.title}>Address</Typography.Paragraph>
            <Typography.Paragraph style={isSelectedCard ? styles.valuesWhite : styles.values}>
              {address.street1} {address.city}, {address?.subdivision?.localCode ?? ''} {address.country.alpha2Code}
            </Typography.Paragraph>
          </Col>
        </Col>
      </Row>
    </Card>
  );
};
