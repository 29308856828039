/* eslint-disable no-debugger */
import { Col, Row } from 'antd';
import { BlockLoader } from 'components/common/BlockLoader';
import { Loader } from 'components/common/Loader';
import WimsicalError from 'components/common/WimsicalError/WimsicalError';
import NoResults from 'components/common/noResults/noResults';
import { formatErrorMessage } from 'helpers/formatErrorMessage';
import { QueryErrorModel } from 'models/ErrorModel';
import { useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useGetSparingRequestConfigurationTypesQuery } from 'redux/services/calvin/sparingRequestConfigTypes';
import { incrementOffset, resetParams } from 'redux/services/twittlebugs/sparingParams';
import { useGetSparingRequestsQuery } from 'redux/services/twittlebugs/sparingRequestsApi';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { DeploymentCard } from './DeploymentCard/DeploymentCard';

export const DeploymentsGrid = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { sparingRequestsParams } = useAppSelector((state) => state);
  const { data, isError, isLoading, isFetching, error } = useGetSparingRequestsQuery(sparingRequestsParams);
  const { data: typeData, isLoading: typeIsLoading, isError: typeIsError, error: configError } = useGetSparingRequestConfigurationTypesQuery();
  const dispatch = useAppDispatch();

  const ref = useRef<InfiniteScroll>(null);

  const handleGetMore = (): void => {
    dispatch(incrementOffset());
  };

  useEffect(() => {
    return () => {
      dispatch(resetParams());
    };
  }, []);

  if (isLoading || typeIsLoading || (isFetching && sparingRequestsParams.offset === 0)) return <Loader loadingMessage="Loading Sparing Requests" />;

  if (isError) {
    const err = error as QueryErrorModel;

    return <WimsicalError title={formatErrorMessage(typeof err.status === 'number' ? err.status : 400)} subTitle={err?.data?.errorMessage ? err.data.errorMessage : ''} statusCode={err.status} />;
  }

  if (data?.data.length === 0) {
    return <NoResults errorMessage="No Results Were Found..." />;
  }

  const requestCardsJSX = data?.data.map((item) => (
    <Col key={item.id} xs={24} sm={24} md={8} lg={8} xl={6} xxl={6}>
      <DeploymentCard sparingTypes={typeData?.data ?? []} sparingRequest={item} />
    </Col>
  ));

  return (
    <InfiniteScroll
      ref={ref}
      style={{ overflowX: 'hidden', width: '100%' }}
      dataLength={data?.data.length ?? 0}
      next={handleGetMore}
      hasMore={Boolean((data?.data.length ?? 0) < (data?.totalCount ?? 0))}
      loader={
        <div style={{ paddingTop: '30px' }}>
          <BlockLoader direction="loader loader--slideUp" />
        </div>
      }>
      <Row gutter={[16, 16]}>{requestCardsJSX}</Row>
    </InfiniteScroll>
  );
};
