import { Checkbox, Row, Spin, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Space from 'antd/lib/space';
import { Loader } from 'components/common/Loader';
import { LocalDeliveryQuantityInput } from 'components/common/LocalDeliveryQuantityInput';
import { useField, useFormikContext } from 'formik';
import { InvOnHandResp } from 'models/DeploymentRequestConfig';
import { ProductPayload } from 'models/Product';
import { SparingRequestPayload, SublinePayload } from 'models/SparingRequests';
import { FC } from 'react';
import { useGetInventoryQuery } from 'redux/services/mrJohnson/mrJohnsonApi';
import { useAppSelector } from 'redux/store';

export type Props = {
  record: ProductPayload;
  idx: number;
};

export const LocalDeliveryRow: FC<Props> = ({ record, idx }) => {
  const { values } = useFormikContext<SparingRequestPayload>();
  const { fullShippingAddress } = useAppSelector((state) => state.form);
  const [{ value }, , { setValue }] = useField<SublinePayload[]>(`lineItems[${idx}].subLines`);

  const found = value?.find((item) => item.serviceLevelAgreement.toLowerCase() === 'Rush'.toLowerCase() || item.serviceLevelAgreement.toLowerCase() === 'WillCall'.toLowerCase());

  const handleGetTotalQuantity = (allInventoryTotals: SublinePayload[]): number => {
    if (allInventoryTotals.length === 0) return 0;
    else {
      const sumTotal = (arr: SublinePayload[]): number => arr.reduce((sum, { quantity }) => sum + (quantity ?? 0), 0);
      const totalInventory = sumTotal(allInventoryTotals);

      return totalInventory;
    }
  };

  const handleGetTotalQuantityAvailable = (allInventoryTotals: InvOnHandResp[]): number => {
    if (allInventoryTotals.length === 0) return 0;
    else {
      const sumTotal = (arr: InvOnHandResp[]): number => arr.reduce((sum, { quantityAvailable }) => sum + quantityAvailable, 0);
      const totalInventory = sumTotal(allInventoryTotals);

      return totalInventory;
    }
  };

  const { data, isLoading, isError } = useGetInventoryQuery(
    {
      product: record.productNumber.toUpperCase(),
      typeId: values.sparingRequestTypeId as string,
      payload: { shippingAddress: fullShippingAddress as any, sparingDeliveryOption: 'LocalPickup' }
    },
    { skip: !values.sparingRequestTypeId || !fullShippingAddress }
  );

  const handleChangeRush = (e: CheckboxChangeEvent): void => {
    if (e.target.checked) {
      setValue([...(value ?? []), { serviceLevelAgreement: 'Rush', quantity: 0 }]);
    } else {
      setValue(value.filter((item) => item.serviceLevelAgreement.toLowerCase() !== 'Rush'.toLowerCase()));
    }
  };
  const handleChangeWillCall = (e: CheckboxChangeEvent): void => {
    if (e.target.checked) {
      setValue([...(value ?? []), { serviceLevelAgreement: 'WillCall', quantity: 0 }]);
    } else {
      setValue(value.filter((item) => item.serviceLevelAgreement.toLowerCase() !== 'WillCall'.toLowerCase()));
    }
  };

  if (handleGetTotalQuantity(value ?? []) === record.quantity && !found)
    return (
      <Row style={{ height: 75, width: '100%' }} align={'middle'} justify={'center'}>
        <Typography.Text>Max quantity reached</Typography.Text>
      </Row>
    );

  return (
    <Spin spinning={isLoading} indicator={<Loader small />} style={{ width: '100%' }}>
      <Space style={{ height: 75 }} size={10}>
        <Space direction="vertical" style={{ marginRight: 15, width: 120 }}>
          <Row gutter={[15, 5]}>
            <Space>
              <Checkbox
                disabled={found?.serviceLevelAgreement.toLowerCase() === 'WillCall'.toLowerCase() || handleGetTotalQuantityAvailable(data ?? []) === 0}
                checked={found?.serviceLevelAgreement.toLowerCase() === 'Rush'.toLowerCase()}
                onChange={handleChangeRush}
              />
              <Typography.Text>Rush - 4 hours</Typography.Text>
            </Space>
          </Row>
          <Row gutter={[15, 5]}>
            <Space>
              <Checkbox
                disabled={found?.serviceLevelAgreement.toLowerCase() === 'Rush'.toLowerCase() || handleGetTotalQuantityAvailable(data ?? []) === 0}
                checked={found?.serviceLevelAgreement.toLowerCase() === 'WillCall'.toLowerCase()}
                onChange={handleChangeWillCall}
              />
              <Typography.Text>Will Call</Typography.Text>
            </Space>
          </Row>
        </Space>
        <Space direction="vertical">
          <LocalDeliveryQuantityInput
            idx={idx}
            disabled={found?.serviceLevelAgreement.toLowerCase() !== 'Rush'.toLowerCase() && found?.serviceLevelAgreement.toLowerCase() !== 'WillCall'.toLowerCase()}
            record={record}
          />
          <Typography.Text type={isError ? 'danger' : undefined}>{isError ? 'Error' : `${handleGetTotalQuantityAvailable(data ?? []) ?? 0} available`}</Typography.Text>
        </Space>
      </Space>
    </Spin>
  );
};
