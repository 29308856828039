import { Col, Row } from 'antd';
import { ColoredHeaderCard } from 'components/common/ColoredHeaderCard';
import { useFormikContext } from 'formik';
import { DeploymentRequestPayload } from 'models/DeploymentRequest';
import { DefectiveReturnsHeader } from 'pages/Defective/DefectiveReturnsHeader';
import { DefectiveReturnsTable } from 'pages/Defective/DefectiveReturnsTable';
import { colors, toRgba } from 'styles/colors';

export const DefectiveReturnsStep = (): JSX.Element => {
  const { values } = useFormikContext<DeploymentRequestPayload>();

  return (
    <Row gutter={[12, 12]}>
      <Col span={8} style={{ marginBottom: 30 }}>
        <DefectiveReturnsHeader />
      </Col>
      <Col span={16} style={{ marginBottom: 30 }}>
        <ColoredHeaderCard title="Line Items" headerColor={toRgba(colors.robinEggBlue, 0.4)}>
          <DefectiveReturnsTable products={values.lineItems} />
        </ColoredHeaderCard>
      </Col>
    </Row>
  );
};
