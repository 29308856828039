import { Col, Row } from 'antd';
import { ColoredHeaderCard } from 'components/common/ColoredHeaderCard';
import { colors, toRgba } from 'styles/colors';
import { DefectiveReturnsHeader } from './DefectiveReturnsHeader';
import { DefectiveReturnsTableMock } from './DefectiveReturnsTableMock';

export const lineItems = [
  {
    alternateItemId: '1234',
    id: '5de51403-3806-4904-af7d-e6f01e0cf26d',
    category: 'SWITCH',
    manufacturer: 'RUCKUS',
    productDescription: "don't use this- use the one without the space in front",
    productName: ' ICX7150-C12P-2X1G',
    productNumber: ' ICX7150-C12P-2X1G',
    quantity: 4,
    customerStandardCost: 0
  },
  {
    alternateItemId: '9129',
    id: '837d767e-4a63-43b8-ac3d-aeb5bcbbe2a4',
    category: 'MODULE',
    manufacturer: 'CISCO',
    productDescription: 'CISCO NIM-1GE-CU-SFP WAN NETWORK INTERFACE MODULE',
    productName: 'NIM-1GE-CU-SFP',
    productNumber: ' NIM-1GE-CU-SFP',
    quantity: 2,
    customerStandardCost: 0
  },
  {
    alternateItemId: '3213',
    id: '62e143e0-fa02-44bc-a158-9c9de3e11f52',
    category: 'SOFTWARE',
    manufacturer: 'JUNIPER',
    productDescription: 'SW,A1,IPS,APPSECURE,W/CS,1YR',
    productName: ' S-SRX5600-A1-1',
    productNumber: ' S-SRX5600-A1-1',
    quantity: 3,
    customerStandardCost: 0
  }
];

export const DefectiveReturnsPage = (): JSX.Element => {
  return (
    <Row gutter={[12, 12]} style={{ marginBottom: 35 }}>
      <Col span={8}>
        <DefectiveReturnsHeader />
      </Col>
      <Col span={16}>
        <ColoredHeaderCard headerColor={toRgba(colors.robinEggBlue, 0.4)} title="Line Items">
          <DefectiveReturnsTableMock products={lineItems} />
        </ColoredHeaderCard>
      </Col>
    </Row>
  );
};
