import { Button, Card, Col, Row, Typography } from 'antd';
import { useFormikContext } from 'formik';
import { DeploymentRequestPayload } from 'models/DeploymentRequest';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/store';
import { colors, toRgb, toRgba } from 'styles/colors';

const { Text, Title } = Typography;

const styles: InlineStylesModel = {
  header: {
    backgroundColor: toRgba(colors.illuminatingEmerald, 0.4)
    // height: '18%'
  },
  selectAddressButton: {
    marginBottom: 10,
    width: '50%'
  },
  selectDeliverDateButton: {
    marginBottom: 10,
    width: '80%'
  }
};

export const ShippingInfoContent = (): JSX.Element => {
  const nav = useNavigate();
  const { id } = useParams();
  const { values } = useFormikContext<DeploymentRequestPayload>();
  const { fullOutageAddress, fullShippingAddress } = useAppSelector((state) => state.form);

  return (
    <Card
      headStyle={styles.header}
      style={{ height: '100%' }}
      title={
        <Title level={4} style={{ fontWeight: 'normal' }}>
          Shipping Information
        </Title>
      }>
      <Row gutter={[32, 0]}>
        <Col span={12}>
          <Typography.Title level={5} style={{ color: fullOutageAddress ? 'black' : toRgb(colors.redSalsa) }}>
            Outage Address:
          </Typography.Title>
          {fullOutageAddress ? (
            <Typography.Paragraph style={styles.infoText}>
              {fullOutageAddress?.name}: {fullOutageAddress?.code} <br /> {fullOutageAddress?.street1}, {fullOutageAddress?.street2 ? fullOutageAddress.street2 + ',' : ''} {fullOutageAddress?.city}{' '}
              {fullOutageAddress?.subdivisionLocalCode ?? ''} {fullOutageAddress?.postalCode} {fullOutageAddress?.countryAlpha2Code}
            </Typography.Paragraph>
          ) : (
            <Button onClick={() => (id ? nav(`/edit/${id}/outage-location`) : nav('/create/outage-location'))} type="ghost" style={styles.selectAddressButton}>
              Select an Outage Address
            </Button>
          )}
        </Col>
        <Col span={12}>
          <Typography.Title level={5} style={{ color: fullShippingAddress ? 'black' : toRgb(colors.redSalsa) }}>
            Shipping Address:
          </Typography.Title>
          {fullShippingAddress ? (
            <Typography.Paragraph style={styles.infoText}>
              {fullShippingAddress?.name}: {fullShippingAddress?.code} <br /> {fullShippingAddress?.street1}, {fullOutageAddress?.street2 ? fullOutageAddress.street2 + ',' : ''}{' '}
              {fullShippingAddress?.city} {fullShippingAddress?.subdivisionLocalCode ?? ''} {fullShippingAddress?.postalCode} {fullShippingAddress?.countryAlpha2Code}
            </Typography.Paragraph>
          ) : (
            <Button onClick={() => (id ? nav(`/edit/${id}/shipping-location`) : nav('/create/shipping-location'))} type="ghost" style={styles.selectAddressButton}>
              Select a Shipping Address
            </Button>
          )}
        </Col>
        {/* <Col span={10}>
          <Typography.Title level={5} style={{ color: values.requestedDeliveryDate ? 'black' : toRgb(colors.redSalsa) }}>
            Requested Delivery Date:
          </Typography.Title>
          {values.requestedDeliveryDate ? (
            <Typography.Paragraph style={styles.infoText}>{values.requestedDeliveryDate && moment(values.requestedDeliveryDate).format('MM-DD-YYYY')}</Typography.Paragraph>
          ) : (
            <Button onClick={() => (id ? nav(`/edit/${id}/request-info`) : nav('/create/request-info'))} type="ghost" style={styles.selectDeliverDateButton}>
              Select Delivery Date
            </Button>
          )}
          <Typography.Title level={5} style={styles.infoTitle}>
            Ship Complete Required:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>
            <Switch disabled={true} checked={values.isShipCompleteRequired} />
          </Typography.Paragraph>
        </Col> */}
      </Row>
    </Card>
  );
};
