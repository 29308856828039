import { Col, Menu, Space, Tooltip } from 'antd';
import { LottieIcon } from 'components/common/LottieIcon';
import { useFormikContext } from 'formik';
import { SparingRequestPayload } from 'models/SparingRequests';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { setProductsView } from 'redux/slices/formSlice';
import { useAppSelector } from 'redux/store';
import { customerLottie, defectiveLottie, deliveryLottie, headerInfoLottie, otherLottie, outageLottie, productsLottie, reviewOrderLottie, serviceLottie } from '../../../assets/customer-lottie';

export enum CreateRequestMenu {
  RequestDetails = 'request-details',
  OutageLocation = 'outage-location',
  Products = 'products',
  ShippingLocation = 'shipping-location',
  RequestInfo = 'request-info',
  Contact = 'contact-info',
  ReviewOrder = 'review-order',
  ServiceLevel = 'service-level',
  DefectiveReturns = 'defective-returns'
}

export const StepControls = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id: deploymentRequestId } = useParams();

  const { values, setValues } = useFormikContext<SparingRequestPayload>();

  const { fullOutageAddress } = useAppSelector((state) => state.form);

  const isGeneralComplete = Boolean(values.sparingRequestTypeId);

  const isSlaValid = values.lineItems
    .map((line) => {
      if (!line?.subLines?.length) return false;
      const totalSublineQty = (line?.subLines ?? [])?.reduce((sum, curr) => sum + (curr.quantity ?? 0), 0);

      return totalSublineQty === line.quantity;
    })
    .every((item) => item);

  const validOutageLocationStep = values.outageLocationId;
  const validProductsStep = !!values.lineItems?.length;
  const validShippingLocationStep = values.shippingLocationId;
  const validContactInfoStep = values.contactEmail !== '' && values.contactName !== '' && values.contactPhoneNumber !== '';

  const currentLocation = useMemo(() => {
    if (pathname.includes(CreateRequestMenu.OutageLocation)) {
      return CreateRequestMenu.OutageLocation;
    } else if (pathname.includes(CreateRequestMenu.Products)) {
      return CreateRequestMenu.Products;
    } else if (pathname.includes(CreateRequestMenu.ShippingLocation)) {
      return CreateRequestMenu.ShippingLocation;
    } else if (pathname.includes(CreateRequestMenu.RequestInfo)) {
      return CreateRequestMenu.RequestInfo;
    } else if (pathname.includes(CreateRequestMenu.ServiceLevel)) {
      return CreateRequestMenu.ServiceLevel;
    } else if (pathname.includes(CreateRequestMenu.DefectiveReturns)) {
      return CreateRequestMenu.DefectiveReturns;
    } else if (pathname.includes(CreateRequestMenu.Contact)) {
      return CreateRequestMenu.Contact;
    } else if (pathname.includes(CreateRequestMenu.ReviewOrder)) {
      return CreateRequestMenu.ReviewOrder;
    } else {
      return CreateRequestMenu.RequestDetails;
    }
  }, [pathname]);

  const handleNavigationCreate = (info: { key: string }): void => {
    if (info.key === CreateRequestMenu.RequestDetails) {
      navigate('/create');
    } else if (info.key === CreateRequestMenu.OutageLocation) {
      navigate('/create/outage-location');
    } else if (info.key === CreateRequestMenu.Products) {
      dispatch(setProductsView('grid'));
      navigate('/create/products');
    } else if (info.key === CreateRequestMenu.ShippingLocation) {
      navigate('/create/shipping-location');
    } else if (info.key === CreateRequestMenu.RequestInfo) {
      navigate('/create/request-info');
    } else if (info.key === CreateRequestMenu.ServiceLevel) {
      navigate('/create/service-level');
    } else if (info.key === CreateRequestMenu.DefectiveReturns) {
      navigate('/create/defective-returns');
    } else if (info.key === CreateRequestMenu.Contact) {
      navigate('/create/contact-info');
    } else if (info.key === CreateRequestMenu.ReviewOrder) {
      navigate('/create/review-order');
    }
  };
  const handleNavigationEdit = (info: { key: string }): void => {
    if (info.key === CreateRequestMenu.RequestDetails) {
      navigate(`/edit/${deploymentRequestId}`);
    } else if (info.key === CreateRequestMenu.OutageLocation) {
      navigate(`/edit/${deploymentRequestId}/outage-location`);
    } else if (info.key === CreateRequestMenu.Products) {
      dispatch(setProductsView('grid'));
      navigate(`/edit/${deploymentRequestId}/products`);
    } else if (info.key === CreateRequestMenu.ShippingLocation) {
      navigate(`/edit/${deploymentRequestId}/shipping-location`);
    } else if (info.key === CreateRequestMenu.DefectiveReturns) {
      navigate(`/edit/${deploymentRequestId}/defective-returns`);
    } else if (info.key === CreateRequestMenu.ServiceLevel) {
      navigate(`/edit/${deploymentRequestId}/service-level`);
    } else if (info.key === CreateRequestMenu.RequestInfo) {
      navigate(`/edit/${deploymentRequestId}/request-info`);
    } else if (info.key === CreateRequestMenu.Contact) {
      navigate(`/edit/${deploymentRequestId}/contact-info`);
    } else if (info.key === CreateRequestMenu.ReviewOrder) {
      navigate(`/edit/${deploymentRequestId}/review-order`);
    }
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Menu onClick={deploymentRequestId ? handleNavigationEdit : handleNavigationCreate} mode="horizontal" style={{ justifyContent: 'center' }} selectedKeys={[currentLocation]}>
        <Menu.Item style={{ paddingLeft: 10, paddingRight: 10 }} key={CreateRequestMenu.RequestDetails}>
          <LottieIcon required complete={isGeneralComplete} lottieFile={headerInfoLottie} title="Request Identification" />
        </Menu.Item>
        <Menu.Item style={{ paddingLeft: 10, paddingRight: 10 }} key={CreateRequestMenu.OutageLocation}>
          <LottieIcon required title="Outage Location" lottieFile={outageLottie} complete={Boolean(validOutageLocationStep)} />
        </Menu.Item>
        <Menu.Item style={{ paddingLeft: 10, paddingRight: 10 }} key={CreateRequestMenu.ShippingLocation}>
          <LottieIcon required title="Shipping Location" lottieFile={deliveryLottie} complete={Boolean(validShippingLocationStep)} />
        </Menu.Item>
        <Menu.Item style={{ paddingLeft: 10, paddingRight: 10 }} disabled={!validShippingLocationStep || !isGeneralComplete} key={CreateRequestMenu.Products}>
          <Tooltip title={(!validShippingLocationStep || !isGeneralComplete) && 'Select a shipping address and sparing request type  to access this section.'}>
            <Col>
              <LottieIcon lottieFile={productsLottie} complete={validProductsStep} required title="Products" />
            </Col>
          </Tooltip>
        </Menu.Item>
        <Menu.Item style={{ paddingLeft: 10, paddingRight: 10 }} disabled={!validShippingLocationStep || !isGeneralComplete} key={CreateRequestMenu.ServiceLevel}>
          <Tooltip title={(!validShippingLocationStep || !isGeneralComplete) && 'Select a shipping address and sparing request type to access this section.'}>
            <Col>
              <LottieIcon complete={!!values.lineItems.length && isSlaValid} lottieFile={serviceLottie} required title="Service Level" />
            </Col>
          </Tooltip>
        </Menu.Item>
        <Menu.Item style={{ paddingLeft: 10, paddingRight: 10 }} key={CreateRequestMenu.Contact}>
          <LottieIcon required title="Contact" lottieFile={customerLottie} complete={Boolean(validContactInfoStep)} />
        </Menu.Item>
        <Menu.Item style={{ paddingLeft: 10, paddingRight: 10 }} key={CreateRequestMenu.RequestInfo}>
          <LottieIcon title="Other" complete={false} lottieFile={otherLottie} />
        </Menu.Item>
        <Menu.Item style={{ paddingLeft: 10, paddingRight: 10 }} disabled key={CreateRequestMenu.DefectiveReturns}>
          <Tooltip title={'Coming soon'}>
            <Col>
              <LottieIcon title="Defective Returns" lottieFile={defectiveLottie} complete={false} />
            </Col>
          </Tooltip>
        </Menu.Item>
        <Menu.Item style={{ paddingLeft: 10, paddingRight: 10 }} key={CreateRequestMenu.ReviewOrder}>
          <LottieIcon complete={false} title="Review Order" lottieFile={reviewOrderLottie} />
        </Menu.Item>
      </Menu>
    </Space>
  );
};
