import { Button, message, Modal, Row, Space, Spin, Typography } from 'antd';
import { BlockLoader } from 'components/common/BlockLoader';
import { DeploymentRequest } from 'models/DeploymentRequest';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDeleteSparingRequestMutation, useGetSparingRequestsQuery, useRecoverSparingRequestMutation } from 'redux/services/twittlebugs/sparingRequestsApi';
import { setSelectedSparingRequests } from 'redux/slices/selectedDeploymentRequestSlice';
import { ReduxState } from 'redux/store';

interface Props {
  deletedDeploymentRequests?: DeploymentRequest[] | undefined;
}

export const RecoverActions = ({ deletedDeploymentRequests }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const naviagte = useNavigate();
  const [showPurgeModal, _setShowPurgeModal] = useState(false);
  const [deploymentRequestCount, _setDeploymentRequestCount] = useState(1);
  const { selectedSparingRequests } = useSelector((state: ReduxState) => state.selectedSparingRequests);
  const { sparingRequestsParams } = useSelector((state: ReduxState) => state);
  const { data: sparingRequestData, isLoading, isFetching } = useGetSparingRequestsQuery({ ...sparingRequestsParams, returnDeletedDataOnly: true });

  const [recoverSparingRequest, { isLoading: isRecovering }] = useRecoverSparingRequestMutation();
  const [purgeSparingRequest, { isLoading: isDeleting }] = useDeleteSparingRequestMutation();

  const totalSelectedDeploymentRequests = selectedSparingRequests.length;

  const handleRestoreDeploymentRequest = async (): Promise<void> => {
    if (selectedSparingRequests.length < 1) {
      message.error('No entity selected!');

      return;
    }

    for (const deploymentRequest of selectedSparingRequests) {
      try {
        await recoverSparingRequest(deploymentRequest.id).unwrap();
        message.success(`Sparing Request: ${deploymentRequest.requestNumber} was successfully recovered`);
      } catch {
        message.error(`Sparing Request ${deploymentRequest.requestNumber} failed to be recovered`);
      }
    }

    dispatch(setSelectedSparingRequests([]));
  };

  const handlePurgeItems = async (): Promise<void> => {
    if (selectedSparingRequests.length < 1) {
      message.error('No items have been selected to be deleted!');

      return;
    }
    for (const deploymentRequest of selectedSparingRequests) {
      try {
        await purgeSparingRequest({ id: deploymentRequest.id, params: { wouldYouLikeToPlayAGame: true } }).unwrap();

        _setDeploymentRequestCount((prev) => prev + 1);
        message.success(`Purged sparing requests successfully!`);
      } catch (error) {
        console.log(error, '<<<<');
        message.error((error as { data: string }).data);
      }
    }

    _setShowPurgeModal(false);
    _setDeploymentRequestCount(1);
    dispatch(setSelectedSparingRequests([]));
  };

  const handleSelectAll = (): void => {
    if (sparingRequestData) {
      dispatch(setSelectedSparingRequests(sparingRequestData.data));
    }
  };

  const handleClearAll = (): void => {
    dispatch(setSelectedSparingRequests([]));
  };

  return (
    <Row justify="space-between" style={{ marginTop: 5 }}>
      <Row justify="space-between">
        <Button disabled={!sparingRequestData?.data.length} onClick={handleSelectAll} style={{ marginRight: 5 }} type="primary">
          Select All
        </Button>
        <Button danger ghost disabled={!totalSelectedDeploymentRequests} onClick={handleClearAll} style={{ marginRight: 5 }}>
          Clear All
        </Button>
      </Row>
      <Row justify="space-between">
        <Button
          onClick={handleRestoreDeploymentRequest}
          style={totalSelectedDeploymentRequests ? { marginRight: 5, background: '#4e937a', borderColor: '#4e937a', color: 'white' } : { marginRight: 5 }}
          loading={isRecovering}
          disabled={!totalSelectedDeploymentRequests}>
          Restore Selected ({totalSelectedDeploymentRequests})
        </Button>
        <Button onClick={(): void => _setShowPurgeModal(!showPurgeModal)} danger loading={isDeleting} disabled={!totalSelectedDeploymentRequests}>
          Purge Selected ({totalSelectedDeploymentRequests})
        </Button>
      </Row>
      <Modal
        title="Are you sure?"
        okText={`Purge Items (${totalSelectedDeploymentRequests})`}
        open={showPurgeModal}
        onOk={handlePurgeItems}
        closable={!isDeleting}
        maskClosable={!isDeleting}
        onCancel={(): void => _setShowPurgeModal(false)}
        cancelButtonProps={isDeleting ? { style: { pointerEvents: 'none', opacity: '.3' } } : undefined}
        okButtonProps={isDeleting ? { style: { background: '#dc3545', pointerEvents: 'none', opacity: '.3', border: 'none' } } : { style: { background: '#dc3545', border: 'none' } }}>
        <Spin
          spinning={isDeleting}
          indicator={
            <Space style={{ width: '100%', marginLeft: '-50%', marginTop: '-37%' }}>
              <BlockLoader direction="loader loader--slideUp" message={`Purging Request ${deploymentRequestCount} of ${totalSelectedDeploymentRequests}...`} />
            </Space>
          }>
          <Typography.Text type="danger">IF YOU CONTINUE, YOU WILL NO LONGER BE ABLE TO RECOVER THE SELECTED SPARING REQUEST(S).</Typography.Text>
        </Spin>
      </Modal>
    </Row>
  );
};

export default RecoverActions;
