import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SparingRequest } from 'models/SparingRequests';

interface InitialState {
  selectedSparingRequests: SparingRequest[];
}

const initialState: InitialState = {
  selectedSparingRequests: []
};

export const selectedSparingRequests = createSlice({
  name: 'selectedSparingRequests',
  initialState,
  reducers: {
    setSelectedSparingRequests: (state, { payload }: PayloadAction<SparingRequest[]>) => {
      state.selectedSparingRequests = payload;
    },
    addSelectedSparingRequests: (state, { payload }: PayloadAction<SparingRequest>) => {
      state.selectedSparingRequests = [...state.selectedSparingRequests, payload];
    },
    removeSelectedSparingRequests: (state, { payload }: PayloadAction<SparingRequest>) => {
      state.selectedSparingRequests = state.selectedSparingRequests.filter((item) => item.id !== payload.id);
    }
  }
});

export const { setSelectedSparingRequests, addSelectedSparingRequests, removeSelectedSparingRequests } = selectedSparingRequests.actions;
