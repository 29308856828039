import { Col, Row } from 'antd';
import { HeaderFilters, ProductsCart, ProductsGrid } from 'components/Create/Products';
import CartMenu from 'components/Create/Products/ProductsCart/components/CartMenu';
import { useAppSelector } from 'redux/store';

export const ProductsPage = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { productsView } = useAppSelector((state) => state.form);

  return (
    <>
      <HeaderFilters />
      <Row justify="space-between">
        <Col>
          <CartMenu />
        </Col>
      </Row>

      {productsView === 'grid' ? <ProductsGrid /> : <ProductsCart />}
    </>
  );
};
