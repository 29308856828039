import { Modal, ModalProps, Space, Spin, notification } from 'antd';
import { FieldInputDatePicker } from 'components/Create/FormItems/FieldInputDatePicker.tsx';
import { FieldInputSelect } from 'components/Create/FormItems/FieldInputSelect';
import { FieldInputTextArea } from 'components/Create/FormItems/FieldInputTextArea';
import { ColoredCard } from 'components/common/ColoredCard';
import { Loader } from 'components/common/Loader';
import { MappedChoiceStatus } from 'enums/GlobalEnums';
import { FormikProvider, useFormik } from 'formik';
import { QueryErrorModel } from 'models/ErrorModel';
import { SparingOrder } from 'models/PostedSpareOrder';
import { SparingOrderPayload } from 'models/SparingRequests';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useUpdateSparingOrderMutation } from 'redux/services/twittlebugs/sparingRequestsApi';

type Props = ModalProps & {
  sparingOrder?: SparingOrder;
  setIsOpen: React.Dispatch<React.SetStateAction<string>>;
  isOpen: boolean;
};

export const UpdateSparingOrderModal: FC<Props> = ({ sparingOrder, setIsOpen, isOpen, ...rest }) => {
  const [updateSparingOrder] = useUpdateSparingOrderMutation();
  const { id } = useParams();

  const formik = useFormik<SparingOrderPayload>({
    enableReinitialize: true,
    initialValues: {
      fulfillmentStatus: sparingOrder?.fulfillmentStatus || 'Scheduling',
      estimatedDeliveryDate: sparingOrder?.estimatedDeliveryDate ?? '',
      internalNotes: sparingOrder?.notes ?? ''
    },
    onSubmit: async (values) => {
      if (!id || !sparingOrder?.id) {
        notification.error({
          message: 'Error',
          description: 'An error occured, the team has been notified.',
          className: 'custom-class',
          style: {
            width: 600
          },
          duration: 10
        });

        return;
      }

      try {
        const response = await updateSparingOrder({ sparingRequestId: id, sparingOrderid: sparingOrder.id, body: values }).unwrap();

        notification.success({
          message: 'Success',
          description: `Order ${response.salesOrderNumber} updated successfully`,
          style: {
            width: 600
          },
          duration: 10
        });
        setIsOpen('');
        formik.resetForm();
      } catch (e) {
        console.log(e);
        const error = e as QueryErrorModel;
        const errorMessage = error?.data?.errorMessage ? error?.data?.errorMessage : error.data ? (error?.data as string) : 'An error occured, the team has been notified.';

        notification.error({
          message: 'Error',
          description: errorMessage,
          className: 'custom-class',
          style: {
            width: 600
          },
          duration: 10
        });
      }
    }
  });

  const handleSubmit = (): void => {
    formik.submitForm();
  };

  const handleCancel = (): void => {
    setIsOpen('');
    formik.resetForm();
  };

  const handleOptions = (order: string | undefined): string[] => {
    switch (order) {
      case 'NextFlightOut':
        return ['Scheduling', MappedChoiceStatus.Postack, MappedChoiceStatus.Stage, MappedChoiceStatus.OrderShip, MappedChoiceStatus.Pod, MappedChoiceStatus.Redeliver, 'Cancelled'];

      case 'Rush':
        return ['Scheduling', MappedChoiceStatus.Postack, MappedChoiceStatus.Stage, MappedChoiceStatus.OrderShip, MappedChoiceStatus.Pod, MappedChoiceStatus.Redeliver, 'Cancelled'];

      case 'WillCall':
        return ['Scheduling', MappedChoiceStatus.Postack, MappedChoiceStatus.StageWillCall, MappedChoiceStatus.Pod, MappedChoiceStatus.Redeliver, 'Cancelled'];

      case 'NextBusinessDay':
        return ['Scheduling', MappedChoiceStatus.Postack, MappedChoiceStatus.Packed, 'Shipped', MappedChoiceStatus.Pod, 'Cancelled'];

      default:
        return [''];
    }
  };

  return (
    <Modal open={isOpen} onOk={handleSubmit} onCancel={handleCancel} closable={false} {...rest}>
      <Spin spinning={formik.isSubmitting} indicator={<Loader loadingMessage="Updating Order" />}>
        <ColoredCard title={`Edit Sparing Order: ${sparingOrder?.salesOrderNumber}`} color="rgb(206, 213, 242)">
          <FormikProvider value={formik}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <FieldInputSelect
                label="Status"
                options={handleOptions(sparingOrder?.serviceLevelAgreement).map((status) => ({ label: status, value: status.replace(/ /g, '') }))}
                fieldName="fulfillmentStatus"
              />
              <FieldInputDatePicker label="Current ETA (UTC)" fieldName="estimatedDeliveryDate" />
              <FieldInputTextArea label="Notes" fieldName="internalNotes" />
            </Space>
          </FormikProvider>
        </ColoredCard>
      </Spin>
    </Modal>
  );
};
