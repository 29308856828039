import { Button, Col, Row, Space, Typography } from 'antd';
import { UseOutageLocationToggle } from 'components/common/UseOutageLocationToggle';
import { AddDeliveryAddressModal } from 'components/createDeliveryAddress/AddDeliveryAddressModal';
import { useFormikContext } from 'formik';
import { DeploymentRequestLabels } from 'models/DeploymentRequest';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { SparingRequestPayload } from 'models/SparingRequests';
import { useState } from 'react';
import { colors, toRgba } from 'styles/colors';
import { LocationsFilters } from '../common';
const { Title } = Typography;

const styles: InlineStylesModel = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: toRgba(colors.illuminatingEmerald, 0.4),
    justifyContent: 'space-between',
    width: '100%',
    padding: 24,
    borderBottom: '2px solid rgba(0,0,0,.1)',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10
  },
  title: {
    margin: 0,
    fontWeight: 'normal',
    borderBottom: 5
  },
  nextButton: {
    margin: 'auto',
    borderRadius: '5px'
  }
};

export const HeaderControls = (): JSX.Element => {
  const { values } = useFormikContext<SparingRequestPayload>();
  const [showAddAddressModal, _setShowAddAddressModal] = useState(false);

  return (
    <Row align="middle" style={styles.container}>
      <Col span={6}>
        <Space style={{ flex: 1, justifyContent: 'center' }} size={3} direction="vertical">
          <Title level={4} style={styles.title}>
            Search For {DeploymentRequestLabels.shippingAddress}
          </Title>
          {values.outageLocationId && <UseOutageLocationToggle />}
        </Space>
      </Col>
      <Col span={18} style={{ textAlign: 'right' }}>
        <Space>
          <LocationsFilters />
          <Button type="primary" style={styles.addAddressButton} onClick={(): void => _setShowAddAddressModal(true)}>
            Add Manual Address
          </Button>
          <AddDeliveryAddressModal
            isShipping
            addressTypes={['Delivery']}
            show={showAddAddressModal}
            key={1}
            onClose={(): void => {
              _setShowAddAddressModal(false);
            }}
          />
        </Space>
      </Col>
    </Row>
  );
};
