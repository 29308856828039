import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, InputNumber, List, Modal, Row, Space, Typography, message } from 'antd';
import { AntCardTitle } from 'components/common/AntCardTitle';
import { useFormikContext } from 'formik';
import { DeploymentRequestPayload } from 'models/DeploymentRequest';
import { InvOnHandResp } from 'models/DeploymentRequestConfig';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { SparingRequestPayload } from 'models/SparingRequests';
import { SubstituteProduct } from 'models/Substitutions';
import { useRef } from 'react';
import { useGetInventoryQuery } from 'redux/services/mrJohnson/mrJohnsonApi';
import { useAppSelector } from 'redux/store';
const { Text } = Typography;

interface Props {
  product: SubstituteProduct;
}

const styles: InlineStylesModel = {
  wrapper: {
    margin: 0,
    height: '100%'
  },
  container: {
    backgroundColor: 'white',
    borderRadius: 5,
    height: '100%',
    border: '1px solid #D7D7D7'
  },
  description: {
    fontSize: '0.8em',
    display: 'flex'
  },
  bottomControls: {
    justifyContent: 'space-between',
    width: '100%',
    justifySelf: 'flex-end'
  },
  quantityInput: {
    width: '60px',
    textAlign: 'center'
  },
  currentCart: {
    textAlign: 'right'
  }
};

export const ProductSubstitutionCard = ({ product }: Props): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { setFieldValue, values } = useFormikContext<SparingRequestPayload>();
  const { fullShippingAddress } = useAppSelector((state) => state.form);

  const { data, isLoading, isError } = useGetInventoryQuery(
    {
      product: product.productNumber.toUpperCase(),
      typeId: values.sparingRequestTypeId as string,
      payload: { shippingAddress: fullShippingAddress as any, sparingDeliveryOption: 'SumOfAll' }
    },
    { skip: !values.sparingRequestTypeId || !fullShippingAddress }
  );

  const handleGetTotalQuantity = (allInventoryTotals: InvOnHandResp[]): number => {
    if (allInventoryTotals.length === 0) return 0;
    else {
      const sumTotal = (arr: InvOnHandResp[]): number => arr.reduce((sum, { quantityAvailable }) => sum + quantityAvailable, 0);
      const totalInventory = sumTotal(allInventoryTotals);

      return totalInventory;
    }
  };

  const selectedProduct = values.lineItems?.find((selectedProduct) => selectedProduct.productNumber === product.productNumber);
  const inputRef = useRef<HTMLInputElement>(null);

  /* ******************** Functions / Variables ******************** */
  const handleChangeQuantity = (): void => {
    if (inputRef.current && Number(inputRef.current.value) > 0) {
      const quantity = Number(inputRef.current.value);

      const quantityWithCart = selectedProduct ? quantity + selectedProduct.quantity : quantity;

      if (quantityWithCart > handleGetTotalQuantity(data ?? [])) {
        message.error('Cannot add more than available quantity');

        return;
      }

      if (selectedProduct) {
        setFieldValue('lineItems' as keyof DeploymentRequestPayload, [
          ...values.lineItems.filter((product) => product.productNumber !== selectedProduct.productNumber),
          { ...selectedProduct, quantity: selectedProduct.quantity + quantity }
        ]);
      } else {
        const payloadProductObject: DeploymentRequestPayload['lineItems'][number] = {
          id: product.id,
          category: product.category,
          manufacturer: product.manufacturer || 'n/a',
          productDescription: product.productDescription,
          productName: product.productName,
          productNumber: product.productNumber,
          quantity: quantity
        };

        setFieldValue('lineItems' as keyof DeploymentRequestPayload, [...values.lineItems, payloadProductObject]);
      }
      inputRef.current.value = '0';
    } else {
      message.warn('Please add a valid quantity...');
    }
  };

  /* ******************** Render ******************** */
  return (
    <List.Item style={styles.wrapper} colStyle={{ height: '100%', marginBottom: 8, padding: 4 }}>
      <Card
        size="small"
        headStyle={{ paddingLeft: 8, paddingRight: 8 }}
        bodyStyle={{ padding: 8, marginTop: 'auto' }}
        style={styles.container}
        title={
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <AntCardTitle level={3} text={product.productName} />
            <Text type="secondary" style={{ fontSize: 12 }}>
              {product.productNumber}
            </Text>
          </div>
        }
        extra={
          <Space style={styles.bottomControls}>
            <Input.Group compact>
              <InputNumber
                disabled={!handleGetTotalQuantity(data ?? []) || handleGetTotalQuantity(data ?? []) < 0}
                ref={inputRef}
                style={styles.quantityInput}
                defaultValue="1"
                precision={0}
                type="int"
              />
              <Button disabled={!handleGetTotalQuantity(data ?? []) || handleGetTotalQuantity(data ?? []) < 0} onClick={handleChangeQuantity} type="ghost" icon={<PlusOutlined />} />
            </Input.Group>
          </Space>
        }>
        <Row gutter={[8, 8]} align="top" style={{ minHeight: 60, paddingBottom: 10 }}>
          <Col span={24}>
            <Text type="secondary" style={styles.description}>
              {product.productDescription}
            </Text>
          </Col>
        </Row>
        <Col span={24}>
          <Row justify="space-between">
            <Space direction="vertical" size={0}>
              <Col>
                <Text>Category</Text>
              </Col>
              <Col>
                <Text type="secondary">{product.category || 'NA'}</Text>
              </Col>
            </Space>
            <Col style={{ textAlign: 'right' }}>
              <Row>
                <Col>
                  <Text style={{ color: '#5867dd' }}>{selectedProduct ? `Cart Contains : ${selectedProduct.quantity}` : null}</Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Row justify="space-between" style={{ width: '100%' }}>
            <Space size={0} direction="vertical">
              <Col>
                <Text>Manufacturer</Text>
              </Col>
              <Col>
                <Text type="secondary">{product.manufacturer || 'NA'}</Text>
              </Col>
            </Space>
            <Space size={0} direction="vertical">
              <Col>
                {isLoading ? (
                  <Col>
                    <Text type="success">
                      Loading: <LoadingOutlined />
                    </Text>
                  </Col>
                ) : isError ? (
                  <Text type="danger">Available: Error</Text>
                ) : (
                  <Text
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      Modal.info({
                        width: 1200,
                        icon: undefined,
                        content: (
                          <List
                            dataSource={data}
                            renderItem={(item): JSX.Element => (
                              <Row style={{ marginBottom: 15 }}>
                                <Typography.Text>{JSON.stringify(item)}</Typography.Text>
                              </Row>
                            )}
                          />
                        )
                      })
                    }
                    type="success">
                    Available: {handleGetTotalQuantity(data ?? [])}
                  </Text>
                )}
              </Col>
            </Space>
          </Row>
        </Col>
      </Card>
    </List.Item>
  );
};
