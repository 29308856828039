import { Col, Row } from 'antd';
import { OrderNameFilter, OrderNumberFilter, ReferenceFilter, RequesterFilter, StatusFilter } from './components';
import { CustomerOrderNumberFilter } from './components/CustomerOrderNumberFilter';

export const ControlFilters = (): JSX.Element => {
  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={24} md={8} lg={5} xl={5} xxl={5}>
        <OrderNameFilter />
      </Col>
      <Col xs={24} sm={24} md={8} lg={5} xl={5} xxl={5}>
        <ReferenceFilter />
      </Col>
      <Col xs={24} sm={24} md={8} lg={5} xl={5} xxl={5}>
        <CustomerOrderNumberFilter />
      </Col>
      <Col xs={24} sm={24} md={8} lg={5} xl={5} xxl={5}>
        <OrderNumberFilter />
      </Col>
      <Col xs={24} sm={24} md={8} lg={5} xl={5} xxl={5}>
        <RequesterFilter />
      </Col>
      <Col>
        <StatusFilter />
      </Col>
    </Row>
  );
};
