import { Col, Row, Space, Typography } from 'antd';
import { TimeZoneText } from 'components/common/TimeZoneText';
import { formatChoiceStatusRaw } from 'helpers/formatChoiceStatus';
import Cookies from 'js-cookie';
import { SparingOrder } from 'models/PostedSpareOrder';
import { FC } from 'react';
import { useAppSelector } from 'redux/store';

const styles = {
  heading: {
    color: 'rgb(47, 85, 150)',
    fontWight: 'bold'
  }
};

interface Props {
  sparingOrder: SparingOrder;
}

const getCookie = (): boolean => {
  const cookie = Cookies.get('isLocalTimeZone');
  const parsedCookie = cookie ? JSON.parse(cookie) : false;

  if (parsedCookie) return true;

  return false;
};

export const CollapseRowHeader: FC<Props> = ({ sparingOrder }): JSX.Element => {
  const { deliveryAddressTimeZoneInfo } = useAppSelector((state) => state.form);

  const formatSLA = (sparingOrder: SparingOrder): string => {
    switch (sparingOrder.serviceLevelAgreement) {
      case 'NextBusinessDay':
        return 'NBD';

      case 'NextFlightOut':
        return 'NFO';

      case 'WillCall':
        return 'Will Call';

      default:
        return 'Rush';
    }
  };

  return (
    <div>
      <Row gutter={[10, 0]} align={'top'}>
        <Col>
          <Space size={5}>
            <Typography.Text style={styles.heading}>Sparing Order:</Typography.Text>
            <Typography.Text>{sparingOrder.salesOrderNumber || 'Failed'}</Typography.Text>
          </Space>
        </Col>
        <Col>
          <Space size={5}>
            <Typography.Text style={styles.heading}>Warehouse:</Typography.Text>
            <Typography.Text>{sparingOrder.warehouseId}</Typography.Text>
          </Space>
        </Col>
        <Col>
          <Space size={5}>
            <Typography.Text style={styles.heading}>SLA: </Typography.Text>
            <Typography.Text>{formatSLA(sparingOrder)}</Typography.Text>
          </Space>
        </Col>
        <Col>
          <Space size={5}>
            <Typography.Text style={styles.heading}>Delivery Method:</Typography.Text>
            <Typography.Text>{sparingOrder.trackingInformation.shipMethod}</Typography.Text>
          </Space>
        </Col>
        <Col>
          <Space size={5}>
            <Typography.Text style={styles.heading}>Current Status:</Typography.Text>
            <Typography.Text>{formatChoiceStatusRaw(sparingOrder)}</Typography.Text>
          </Space>
        </Col>
        <Col>
          <Typography.Text style={styles.heading}>Last Updated:</Typography.Text>
          <TimeZoneText spaceStyle={{ marginLeft: 5 }} timezoneData={deliveryAddressTimeZoneInfo} dateTime={sparingOrder.modifiedDateTime} spaceSize={0} divider />
        </Col>
      </Row>
      <Row gutter={[12, 0]}>
        <Col>
          <Typography.Text style={styles.heading}>Current ETA:</Typography.Text>
          <TimeZoneText spaceStyle={{ marginLeft: 5 }} timezoneData={deliveryAddressTimeZoneInfo} dateTime={sparingOrder.estimatedDeliveryDate} spaceSize={0} divider />
        </Col>
      </Row>
    </div>
  );
};
