import { Col, Row, Typography } from 'antd';
import { Loader } from 'components/common/Loader';
import NoResults from 'components/common/noResults/noResults';
import { useFormikContext } from 'formik';

import WimsicalError from 'components/common/WimsicalError/WimsicalError';
import { formatErrorMessage } from 'helpers/formatErrorMessage';
import { AcuityContext } from 'models/Application';
import { QueryErrorModel } from 'models/ErrorModel';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { SparingRequestPayload } from 'models/SparingRequests';
import { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useGetAddressesQuery } from 'redux/services/chuckieSue/addressesApi';
import { incrementOffset, setAddressTypesInclude } from 'redux/services/chuckieSue/addressesParams';
import { GetAddressesParams, LocationAddressTypes } from 'redux/services/chuckieSue/models/addresses';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { LocationCard, SelectedLocationCard } from './components';

const styles: InlineStylesModel = {
  container: {
    flexGrow: 1,
    alignContent: 'flex-start'
  },
  selectedContainer: {
    flex: 1,
    alignItems: 'flex-start'
  }
};

interface Props {
  addressType: 'shipping' | 'outage';
}

export const OutageLocationsGrid = ({ addressType }: Props): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { values } = useFormikContext<SparingRequestPayload>();
  const { chuckieSueAddressesParams } = useAppSelector((state) => state);
  const { acuityContext } = useAppSelector((state) => state).app;
  const { fullOutageAddress } = useAppSelector((state) => state.form);

  const { configuration } = acuityContext as AcuityContext;

  const dispatch = useAppDispatch();

  const areAllValuesUndefined = (obj: GetAddressesParams): boolean => {
    const filterOutParams = (obj: GetAddressesParams, paramsToExclude: string[]): Partial<GetAddressesParams> => {
      const newObj = { ...obj };

      paramsToExclude.forEach((param) => {
        const val = param as keyof GetAddressesParams;

        delete newObj[val];
      });

      return newObj;
    };

    return Object.values(filterOutParams(obj, ['offset', 'addressTypesInclude'])).every((val) => val === undefined);
  };

  const areAddressesNotDisplayed = Boolean(areAllValuesUndefined(chuckieSueAddressesParams) && !configuration?.addressDetails.displayAddressesByDefault);

  const { data, isLoading, isError, isFetching, error } = useGetAddressesQuery(
    { ...chuckieSueAddressesParams, addressTypesInclude: [LocationAddressTypes.Install] },
    { skip: areAddressesNotDisplayed }
  );

  /* ******************** Functions / Variables ******************** */
  const getMore = (): void => {
    dispatch(incrementOffset());
  };

  useEffect(() => {
    dispatch(setAddressTypesInclude([LocationAddressTypes.Install]));
  }, []);

  /* ******************** Loading Render ******************** */

  if (isError) {
    const err = error as QueryErrorModel;

    return <WimsicalError title={formatErrorMessage(typeof err.status === 'number' ? err.status : 400)} subTitle={err?.data?.errorMessage ? err.data.errorMessage : ''} statusCode={err.status} />;
  }

  if (areAllValuesUndefined(chuckieSueAddressesParams) && !configuration?.addressDetails.displayAddressesByDefault && !values.outageLocationId)
    return (
      <Row justify="center" style={{ marginTop: 25 }}>
        <Typography.Text style={{ fontSize: 20 }}>Please use filters above to begin search..</Typography.Text>
      </Row>
    );

  /* ******************** Error Render ******************** */
  if (isLoading || (isFetching && chuckieSueAddressesParams.offset === 0)) {
    return (
      <Row style={styles.container} gutter={[16, 16]}>
        <Loader />
      </Row>
    );
  }

  /* ******************** No Results Render ******************** */
  if (data?.totalCount === 0) {
    return <NoResults errorMessage={'no locations were found...'} />;
  }

  const selectedCardJSX = (
    <Col key={values.outageLocationId} span={12}>
      {fullOutageAddress ? <SelectedLocationCard address={fullOutageAddress} addressType="outage" /> : null}
    </Col>
  );

  const locationCardsJSX = data?.data
    .filter((address) => address.id !== values?.outageLocationId)
    .map((address) => {
      return (
        <Col xs={24} sm={12} md={12} key={address.id}>
          <LocationCard addressType={addressType} address={address} />
        </Col>
      );
    });

  const hasMore = (data?.totalCount ?? 0) > chuckieSueAddressesParams.offset && (data?.totalCount ?? 0) > 25 && !values.outageLocationId;

  return (
    <InfiniteScroll style={{ width: '100%', marginTop: '8px', overflow: 'visible' }} next={getMore} hasMore={hasMore} loader={<Loader />} dataLength={data?.data.length ?? 0}>
      <Row gutter={[16, 16]}>{values.outageLocationId && values.outageLocationId !== '' ? [...[selectedCardJSX], ...[locationCardsJSX ?? []]] : locationCardsJSX}</Row>
    </InfiniteScroll>
  );
};
