import { Col, Row, Space, Typography } from 'antd';
import { ProductPayload } from 'models/Product';
import { FC } from 'react';

interface Props {
  record: Partial<
    | {
        alternateItemId: string;
        id: string;
        category: string;
        manufacturer: string;
        productDescription: string;
        productName: string;
        productNumber: string;
        quantity: number;
        customerStandardCost: number;
      }
    | ProductPayload
  >;
}

const styles = {
  heading: {
    color: 'rgb(47, 85, 150)',
    fontWight: 'bold'
  }
};

export const PartNumberToolTip: FC<Props> = ({ record }) => {
  return (
    <Row style={{ padding: 10 }}>
      <Space direction="vertical">
        <Col>
          <Typography.Text style={styles.heading}>Manufacturer: </Typography.Text>
          <Typography.Text>{record.manufacturer ?? 'N/A'}</Typography.Text>
        </Col>
        <Col>
          <Typography.Text style={styles.heading} strong>
            Category:{' '}
          </Typography.Text>
          <Typography.Text>{record.category ?? 'N/A'}</Typography.Text>
        </Col>
        <Col>
          <Typography.Text style={styles.heading} strong>
            Description:{' '}
          </Typography.Text>
          <Typography.Text>{record.productDescription ?? 'N/A'}</Typography.Text>
        </Col>
      </Space>
    </Row>
  );
};
