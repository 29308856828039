import { useAuth0 } from '@auth0/auth0-react';
import Cookies from 'js-cookie';
import { AcuityContext } from 'models/Application';
import { useEffect, useState } from 'react';
import { setAccessToken, setAcuityContext, setUser } from 'redux/slices/appSlice';
import { useAppDispatch, useAppSelector } from 'redux/store';

export const useAuthentication = (): { isAppLoading: boolean; cookieNotFound: boolean } => {
  const { accessToken } = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();
  const { isAuthenticated, loginWithRedirect, isLoading, user, getAccessTokenSilently } = useAuth0();
  const [cookieNotFound, setCookieNotFound] = useState(false);
  const [isConfigLoading, setIsConfigLoading] = useState(false);
  const isAppLoading = Boolean(isLoading || isConfigLoading);

  // Check if User is logged in, if not, redirect them to login screen
  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect({ appState: { targetUrl: '/' } });
    }
    if (isAuthenticated && accessToken === null) {
      getAccessTokenSilently().then((accessToken) => {
        dispatch(setAccessToken(accessToken));
      });
    }
  }, [accessToken, dispatch, getAccessTokenSilently, isAuthenticated, isLoading, loginWithRedirect]);

  useEffect(() => {
    if (user) {
      dispatch(setUser(user));
    }
  }, [dispatch, user]);

  // Fetch cookie AND configuration and set in store
  useEffect(() => {
    const cookie = Cookies.get(`${process.env.REACT_APP_COOKIE_PREFIX}_acuity_context`);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const cookieJSON: AcuityContext = cookie ? JSON.parse(cookie) : undefined;

    const divisionId = cookieJSON?.selectedCustomer.id;

    const fetchConfig = async (): Promise<void> => {
      setIsConfigLoading(true);

      const token = await getAccessTokenSilently();

      try {
        const resp = await fetch(`${process.env.REACT_APP_CALVIN_BASE_URL}/divisions/${divisionId}/sparingRequestConfiguration`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-functions-key': `${process.env.REACT_APP_API_HOST_KEY_CALVIN}`,
            authorization: `Bearer ${token}`,
            'Access-Control-Allow-Origin': '*'
          }
        });

        const respJSON = await resp.json();

        if (cookieJSON?.selectedCustomer) {
          dispatch(setAcuityContext({ ...cookieJSON, configuration: respJSON }));
        } else {
          setCookieNotFound(true);
        }

        setIsConfigLoading(false);
      } catch (error) {
        console.log(error, 'config error');
        setIsConfigLoading(false);
      }
    };

    fetchConfig();

    if (cookieNotFound) {
      setTimeout(() => {
        window.location.href = process.env.REACT_APP_ERNIE_REDIRECT_URL || '';
      }, 5000);
    }
  }, [cookieNotFound, dispatch]);

  return { isAppLoading, cookieNotFound };
};
