import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { List, Row, Typography } from 'antd';
import { Loader } from 'components/common/Loader';
import WimsicalError from 'components/common/WimsicalError/WimsicalError';
import NoResults from 'components/common/noResults/noResults';
import { useFormikContext } from 'formik';
import { DeploymentRequestPayload } from 'models/DeploymentRequest';
import { InlineStylesModel } from 'models/InlineStylesModel';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useGetProductsQuery } from 'redux/services/julia/juliaApi';
import { incrementOffset } from 'redux/services/julia/productsParams';
import { ReduxState, useAppDispatch, useAppSelector } from 'redux/store';
import { ProductGridItem } from './components';

const styles: InlineStylesModel = {
  wrapper: {
    margin: 0,
    height: '100%'
  },
  container: {
    height: '100%'
  },
  description: {
    fontSize: '0.8em',
    display: 'flex'
  },
  selectedContainer: {
    flex: 1,
    alignItems: 'flex-start'
  }
};

export const ProductsGrid = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { juliaProductsParams } = useAppSelector((state: ReduxState) => state);
  const { bulkSearchResults } = useAppSelector((state: ReduxState) => state.app);

  const filterValues = Boolean(
    juliaProductsParams.productNumberContains ||
      juliaProductsParams.alternateItemIdContains ||
      juliaProductsParams.categoryNameContains ||
      juliaProductsParams.productDescriptionContains ||
      juliaProductsParams.manufacturerNameContains
  );

  const { values } = useFormikContext<DeploymentRequestPayload>();

  const { data, isFetching, isLoading, isError, error } = useGetProductsQuery({ ...juliaProductsParams }, { skip: !filterValues });
  const dispatch = useAppDispatch();

  /* ******************** Functions / Variables ******************** */
  const getMore = (): void => {
    dispatch(incrementOffset());
  };

  const title = (status: number): string => {
    switch (status) {
      case 400:
        return 'Error';

      case 401:
        return 'Unauthorized';

      case 403:
        return 'Unauthorized. Please check your permissions';

      case 404:
        return 'Oops were unable to find what we were looking for!';

      case 500:
        return 'Internal Error';

      default:
        return 'Error';
    }
  };

  if (!filterValues) {
    return (
      <Row justify="center">
        <Typography.Text style={{ fontSize: 20 }}>Please use filters above to begin search..</Typography.Text>
      </Row>
    );
  }
  if (isError) {
    console.log(error);
    const queryError = error as FetchBaseQueryError;

    return <WimsicalError statusCode={queryError.status} title={title(queryError?.status as number)} />;
  }

  if (isLoading || (isFetching && juliaProductsParams.offset === 0)) {
    return (
      <Row style={styles.container} gutter={[16, 16]}>
        <Loader />
      </Row>
    );
  }

  if (data?.totalCount === 0) {
    return <NoResults errorMessage={'Nothing here'} />;
  }

  const hasMore = data && data.totalCount >= 25 && data.totalCount > juliaProductsParams.offset;

  if (bulkSearchResults?.length)
    return (
      <List
        grid={{ gutter: 8, xs: 1, sm: 2, md: 4, lg: 4, xl: 5, xxl: 5 }}
        dataSource={bulkSearchResults}
        renderItem={(item): JSX.Element => <ProductGridItem product={item} />}
        rowKey={(item): string => item.productNumber}
        style={{ overflow: 'auto', width: '100%', overflowX: 'hidden' }}
      />
    );

  return (
    <InfiniteScroll
      style={{ overflow: 'none' }}
      next={getMore}
      hasMore={hasMore ? hasMore : false}
      loader={
        <div style={{ maxHeight: '200px' }}>
          <Loader />
        </div>
      }
      dataLength={data ? data.data.length : 0}>
      <List
        grid={{ gutter: 8, xs: 1, sm: 2, md: 4, lg: 4, xl: 5, xxl: 5 }}
        dataSource={data?.data}
        renderItem={(item): JSX.Element => <ProductGridItem product={item} />}
        rowKey={(item): string => item.productNumber}
        style={{ overflow: 'auto', width: '100%', overflowX: 'hidden' }}
      />
    </InfiniteScroll>
  );
};
