import { EditOutlined, FormOutlined, InfoCircleOutlined, OrderedListOutlined } from '@ant-design/icons';
import { Button, Checkbox, Space, Table, Tooltip, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { PartNumberToolTip } from 'components/common/PartNumberToolTip';
import { ProductPayload } from 'models/Product';

interface Props {
  products: ProductPayload[];
}

const cols: ColumnType<ProductPayload>[] = [
  {
    title: `Didn't Use`,
    render: () => <Checkbox />,
    align: 'center',
    width: '12ch'
  },
  {
    title: 'Return Part Number',
    dataIndex: 'productNumber',
    ellipsis: true,
    width: '25ch',
    render: (_, record): JSX.Element => {
      return (
        <Space>
          <Tooltip color="white" title={<PartNumberToolTip record={record} />}>
            <InfoCircleOutlined style={{ color: 'rgb(39, 134, 250)' }} />
          </Tooltip>
          <Typography.Text>{record.productNumber}</Typography.Text>
        </Space>
      );
    }
  },
  {
    title: 'Return Serial Number',
    dataIndex: 'serialNumber'
  },
  {
    title: 'Return Qty',
    dataIndex: 'quantity',
    align: 'center',
    width: '13ch'
  },
  {
    title: 'OEM Case Number',
    dataIndex: 'caseNumber'
  },
  {
    title: 'OEM RMA Number',
    dataIndex: 'rmaNumber'
  },

  {
    title: 'Action',
    render: (): JSX.Element => {
      return (
        <Space>
          <Tooltip title="Edit">
            <Button icon={<EditOutlined />} />
          </Tooltip>
          <Tooltip title="Notes">
            <Button icon={<FormOutlined />} />
          </Tooltip>
          <Tooltip title="Waybill">
            <Button icon={<OrderedListOutlined />} />
          </Tooltip>
        </Space>
      );
    },
    align: 'center'
  }
];

export const DefectiveReturnsTableMock: React.FC<Props> = ({ products }) => {
  const singleProductsArray: {
    quantity: number;
    alternateItemId?: string | null | undefined;
    id?: string | undefined;
    category?: string | undefined;
    manufacturer?: string | undefined;
    productDescription?: string | undefined;
    productName?: string | undefined;
    productNumber: string;
    customerStandardCost?: number | undefined;
    customerXRef?: { alternateItemId: string | null; customerStandardCost: number | null } | undefined;
  }[] = [];

  products.forEach((line) => {
    for (let i = 0; i < line?.quantity; i++) {
      singleProductsArray.push({ ...line, quantity: 1 });
    }
  });
  // const mappedData = singleProductsArray.map(({ ...rest }, idx) => ({ caseNumber: `CASE00${idx + 1}`, rmaNumber: `RMA00${idx + 1}`, serialNumber: `SERIAL00${idx + 1}`, ...rest }));

  return <Table dataSource={singleProductsArray} columns={cols} />;
};
