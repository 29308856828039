/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Checkbox, Modal, Space } from 'antd';
import { FormLabel } from 'components/Create/FormItems/FormLabel';
import { useFormikContext } from 'formik';
import { SparingRequestPayload } from 'models/SparingRequests';
import { useDispatch } from 'react-redux';
import { setFullShippingAddress } from 'redux/slices/formSlice';
import { useAppSelector } from 'redux/store';

export const UseOutageLocationToggle = (): JSX.Element => {
  const dispatch = useDispatch();
  const { values, setFieldValue } = useFormikContext<SparingRequestPayload>();
  const { fullOutageAddress } = useAppSelector((state) => state.form);
  const checked = values.shippingLocationId === values.outageLocationId;

  const handleChange = (): void => {
    if (fullOutageAddress && !checked) {
      dispatch(setFullShippingAddress(fullOutageAddress));
      setFieldValue('shippingLocationId', values.outageLocationId);

      return;
    }
    if (values.shippingLocationId && values.lineItems.length) {
      Modal.confirm({
        okText: 'Confirm',
        onOk: () => {
          setFieldValue('shippingLocationId', null);
          setFieldValue('lineItems', []);
          dispatch(setFullShippingAddress(null));
        },
        cancelText: 'Cancel',
        onCancel: () => {
          console.log('canceled popup');
        },
        closable: true,
        title: 'If you change the shipping location, the products will be removed from the cart. Are you sure you want to continue?'
      });

      return;
    }
    dispatch(setFullShippingAddress(null));
    setFieldValue('shippingLocationId', '');
  };

  return (
    <Space onClick={handleChange}>
      <Checkbox checked={checked} />
      <FormLabel label="Use Outage Location" />
    </Space>
  );
};
