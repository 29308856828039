export enum ChoiceStatus {
  Postack = 'POSTACK',
  Stage = 'STAGE',
  OrderShip = 'ORDERSHIP',
  Pod = 'POD',
  Redeliver = 'REDELIVER',
  Packed = 'PACKED'
}

export enum MappedChoiceStatus {
  Postack = 'Scheduled',
  StageWillCall = 'Ready For Pickup',
  Stage = 'Awaiting Driver Pickup',
  OrderShip = 'In Transit',
  Pod = 'Delivered',
  Redeliver = 'Delivery Delay',
  Packed = 'Picked',
  Shipped = 'Shipped',
  Cancelled = 'Cancelled'
}
export enum MappedChoiceStatusRaw {
  Postack = 'Scheduled',
  StageWillCall = 'ReadyForPickup',
  Stage = 'AwaitingDriverPickup',
  OrderShip = 'In Transit',
  Pod = 'Delivered',
  Redeliver = 'DeliveryDelay',
  Packed = 'Picked',
  Shipped = 'Shipped',
  Cancelled = 'Cancelled'
}
