import { ShippingLocationsGrid } from 'components/Create/common/LocationsGrid/ShippingLocationsGrid';
import { HeaderControls } from 'components/Create/ShippingLocation';
import { useFormikContext } from 'formik';
import { DeploymentRequestPayload } from 'models/DeploymentRequest';

export const ShippingLocationPage = (): JSX.Element => {
  const { values } = useFormikContext<DeploymentRequestPayload>();

  return (
    <>
      <HeaderControls />
      {/* {isSelectedCard ? <SelectedLocationCard address={isSelectedCard} addressType={'shipping'} /> : <ShippingLocationsGrid addressType="shipping" />} */}
      {<ShippingLocationsGrid addressType="shipping" />}
    </>
  );
};
