import { Card, Col, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import { PostedSparingRequestLineItem } from 'models/SparingRequests';
import { FC } from 'react';
import { PartInfoTable } from './PartInfoTable';

type Props = {
  lineItems: PostedSparingRequestLineItem[];
};

export const PartsInformationCard: FC<Props> = ({ lineItems }) => {
  return (
    <Card
      headStyle={{ backgroundColor: 'rgba(255, 165, 0, 0.4)' }}
      title={
        <Row justify="space-between">
          <Col>
            <Title level={4} style={{ fontWeight: 'normal' }}>
              Parts Information
            </Title>
          </Col>
        </Row>
      }>
      <PartInfoTable lineItems={lineItems} />
    </Card>
  );
};
