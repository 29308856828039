import { ArrowLeftOutlined } from '@ant-design/icons';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Button, Col, Form, message, Modal, notification, Row, Space, Spin, Typography } from 'antd';
import { Loader } from 'components/common/Loader';
import { PostedLineItemsModal } from 'components/common/PostedLineItemsModal';
import WimsicalError from 'components/common/WimsicalError/WimsicalError';
import { ActionControls, StepControls } from 'components/Create/common';
import { DeploymentRequestInfo } from 'components/Create/common/DeploymentRequestInfo';
import { SparingRequestConfirmation } from 'components/Create/common/SparingtRequestConfirmation.tsx';
import { FormikProvider, useFormik } from 'formik';
import { formatErrorMessage } from 'helpers/formatErrorMessage';
import { QueryErrorModel } from 'models/ErrorModel';
import { SparingRequestPayload } from 'models/SparingRequests';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import {
  useAddUserToSmsNotificationsMutation,
  useCreateAttachmentMutation,
  useGetSparingRequestQuery,
  usePostSparingRequestMutation,
  useRemoveUserFromSmsNotificationsMutation,
  useUpdateSparingRequestMutation
} from 'redux/services/twittlebugs/sparingRequestsApi';
import { resetAttachments } from 'redux/slices/attachmentsSlice';
import { setFullOutageAddress, setFullShippingAddress, setIsExit, setIsPost } from 'redux/slices/formSlice';
import { useAppSelector } from 'redux/store';

export const EditDeploymentRequestLayout = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { id } = useParams();
  const { data, isLoading, isError, isFetching, error } = useGetSparingRequestQuery(id ?? skipToken);
  const nav = useNavigate();
  const { attachments } = useAppSelector((state) => state.attachments);
  const [createAttachment] = useCreateAttachmentMutation();
  const { user, postRequestLoadingMessage } = useAppSelector((state) => state.app);
  const { isExit, isPost } = useAppSelector((state) => state.form);
  const dispatch = useDispatch();
  const [loadingMessage, setLoadingMessage] = useState<string | undefined>(undefined);

  const [editSparing, { data: updateResponse, isSuccess: isUpdateSuccess }] = useUpdateSparingRequestMutation();
  const [postSparingRequest, { data: postedData, isSuccess }] = usePostSparingRequestMutation();

  const [addUserToSms] = useAddUserToSmsNotificationsMutation();
  const [removeUserFromSms] = useRemoveUserFromSmsNotificationsMutation();

  const userNumber = user?.['https://acuity.mdsiinc.com/user/user_metadata']?.phone_number;

  const isUserOptedIn = data?.notificationSms?.some((sparingUser) => sparingUser.userId === user?.sub) ?? false;

  const formik = useFormik<SparingRequestPayload>({
    initialValues: {
      requestNumber: data?.requestNumber?.split('-')[1] ?? '',
      customerReferenceNumber: data?.customerReferenceNumber ?? '',
      customerPurchaseOrderNumber: data?.customerPurchaseOrderNumber ?? '',
      sparingRequestTypeId: data?.sparingRequestTypeId ?? '',
      shippingLocationId: data?.shippingLocation?.id ?? '',
      lineItems: data?.lineItems ?? [],
      outageLocationId: data?.outageLocation?.id ?? '',
      contactEmail: user?.email ?? '',
      contactName: user?.name ?? '',
      contactPhoneNumber: userNumber ?? '',
      requestedDeliveryDate: '',
      notificationEmails: user && user.email ? [user.email] : [],
      notes: data?.notes ?? '',
      isLabConfigurationRequired: data?.isLabConfigurationRequired ?? false,
      isActive: true,
      isDeleted: false,
      isUserOptedIn
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoadingMessage('Updating sparing request...');

      const body: SparingRequestPayload = {
        ...values,
        requestNumber: `${data?.requestNumber.split('-')[0]}${values.requestNumber && `-${values.requestNumber}`}`,
        lineItems: values.lineItems.map((item, idx) => ({
          productNumber: item.productNumber,
          lineNumber: idx + 1,
          quantity: item.quantity,
          subLines: item.subLines
        }))
      };

      if (!id) return message.error('An error occured, the team has been notified.');

      try {
        const response = await editSparing({ id, body }).unwrap();

        if (isUserOptedIn && !values.isUserOptedIn) {
          await removeUserFromSms(id).unwrap();
        }

        if (!isUserOptedIn && values.isUserOptedIn) {
          await addUserToSms({ id, payload: {} }).unwrap();
        }

        if (attachments.length) {
          setLoadingMessage('Updating attachments...');

          for await (const attachment of attachments) {
            const formData = new FormData();

            formData.append(`${attachment.name}`, attachment);
            await createAttachment({ file: formData, id }).unwrap();
          }
        }
        if (!isExit && !isPost && response) {
          notification.success({
            message: 'Success',
            description: 'Sparing request updated successfully.',
            className: 'custom-class',
            style: {
              width: 600
            },
            duration: 10
          });
        }

        if (isPost) {
          setLoadingMessage(undefined);
          await postSparingRequest({ sparingResponse: response, sparingRequestId: id }).unwrap();

          dispatch(setIsPost(false));
        }
        dispatch(resetAttachments());
      } catch (e) {
        setLoadingMessage(undefined);
        console.error(e, 'error');

        const error = e as QueryErrorModel;
        const errorMessage = error?.data?.errorMessage ? error?.data?.errorMessage : error.data ? (error?.data as string) : 'An error occured, the team has been notified.';

        dispatch(setIsPost(false));
        dispatch(setIsExit(false));

        notification.error({
          message: 'Error',
          description: errorMessage,
          className: 'custom-class',
          style: {
            width: 600
          },
          duration: 10
        });
      }
    }
  });

  const handleClose = (): void => {
    Modal.destroyAll();
    if (isExit) nav('/');
    else nav(`/edit/${updateResponse?.id}`);
    dispatch(setIsExit(false));
  };

  useEffect(() => {
    if (data?.outageLocation) dispatch(setFullOutageAddress(data?.outageLocation));
    if (data?.shippingLocation) dispatch(setFullShippingAddress(data?.shippingLocation));
  }, [data?.outageLocation, data?.shippingLocation]);

  useEffect(() => {
    return () => {
      dispatch(setFullOutageAddress(null));
      dispatch(setFullShippingAddress(null));
      dispatch(resetAttachments());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <Loader loadingMessage="Loading sparing request..." />;

  if (isError) {
    const err = error as QueryErrorModel;

    return (
      <Space direction="vertical" style={{ width: '100%' }}>
        <Row justify={'space-between'} style={{ width: '100%' }}>
          <Col>
            <Typography.Title level={4}>Edit Sparing Request</Typography.Title>
          </Col>
          <Col>
            <Space>
              <Button onClick={(): void => nav('/search')} icon={<ArrowLeftOutlined />}>
                Back to Search
              </Button>
            </Space>
          </Col>
        </Row>
        <WimsicalError title={formatErrorMessage(typeof err.status === 'number' ? err.status : 400)} subTitle={err?.data?.errorMessage ? err.data.errorMessage : ''} statusCode={err.status} />
      </Space>
    );
  }

  return (
    <>
      <FormikProvider value={formik}>
        <Spin spinning={formik.isSubmitting || !!postRequestLoadingMessage || isFetching} indicator={<Loader loadingMessage={postRequestLoadingMessage ?? loadingMessage} />}>
          <Form layout="vertical">
            <ActionControls />
            <DeploymentRequestInfo />
            <StepControls />
            <Outlet />
          </Form>
        </Spin>
      </FormikProvider>
      <Modal
        onOk={handleClose}
        open={isUpdateSuccess && !isPost && isExit}
        cancelButtonProps={{ style: { display: 'none' } }}
        onCancel={handleClose}
        closeIcon={undefined}
        bodyStyle={{ background: 'linear-gradient(to right bottom, #979797, #acacac, #c1c1c1, #d7d7d7, #ededed)', padding: 0, height: 400, position: 'relative', borderRadius: 5 }}>
        <SparingRequestConfirmation confirmationInfo={updateResponse} />
      </Modal>
      <PostedLineItemsModal open={isSuccess} submittedSparingRequest={postedData?.data} />
    </>
  );
};
