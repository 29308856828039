import { FancyInput } from 'components/ui/Inputs';
import React, { useEffect } from 'react';
import { setAlternateItemIdContains } from 'redux/services/julia/productsParams';
import { useAppDispatch } from 'redux/store';
import { useDebouncedCallback } from 'use-debounce';

interface Props {
  alternateIdTitle: string;
}

export const AlternateIdFilter = ({ alternateIdTitle }: Props): JSX.Element => {
  /* ******************** Hooks ******************** */
  const dispatch = useAppDispatch();

  /* ******************** Functions ******************** */
  const handleChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    dispatch(setAlternateItemIdContains(value || undefined));
  }, 500);

  // Clears state on unmount
  useEffect(() => {
    return (): void => {
      dispatch(setAlternateItemIdContains(undefined));
    };
  }, [dispatch]);

  return <FancyInput placeholder={alternateIdTitle} onChange={handleChange} />;
};
