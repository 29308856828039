import { SendOutlined } from '@ant-design/icons';
import { Button, Tooltip, message, notification } from 'antd';
import { QueryErrorModel } from 'models/ErrorModel';
import { SparingOrder } from 'models/PostedSpareOrder';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useResendSparingOrderNotificationsMutation } from 'redux/services/twittlebugs/sparingRequestsApi';

type Props = {
  order: SparingOrder;
};

export const ResendNotificationsButton: FC<Props> = ({ order }) => {
  const { id } = useParams();
  const [resendNotifications, { isLoading }] = useResendSparingOrderNotificationsMutation();

  const handleResendNotifications = async (): Promise<void> => {
    if (!id) return message.error('An error occured, the team has been notified.');
    try {
      await resendNotifications({ payload: order, sparingOrderId: order.id, sparingRequestId: id }).unwrap();
      notification.success({
        message: 'Success',
        description: 'Notifications sent successfully.',
        className: 'custom-class',
        style: {
          width: 600
        },
        duration: 10
      });
    } catch (error) {
      const err = error as QueryErrorModel;
      const errorMessage = err?.data?.errorMessage ? err?.data?.errorMessage : err.data ? (err?.data as string) : 'An error occured, the team has been notified.';

      notification.error({
        message: 'Error',
        description: errorMessage,
        className: 'custom-class',
        style: {
          width: 600
        },
        duration: 10
      });

      console.log(err);
    }
  };

  return (
    <Tooltip title="Resend Notifications">
      <Button
        loading={isLoading}
        onClick={(ev): void => {
          ev.stopPropagation();
          handleResendNotifications();
        }}
        icon={<SendOutlined />}
      />
    </Tooltip>
  );
};
