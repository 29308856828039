import { Button } from 'antd';
import { useFormikContext } from 'formik';
import { SparingRequestPayload } from 'models/SparingRequests';
import { useAppSelector } from 'redux/store';

export const ContactInfoSwitch = (): JSX.Element => {
  const { user } = useAppSelector((state) => state.app);
  const { handleChange } = useFormikContext<SparingRequestPayload>();

  const handleSetMyContactInfo = (): void => {
    handleChange('contactName')(user?.name || '');
    handleChange('contactPhoneNumber')(user?.['https://acuity.mdsiinc.com/user/user_metadata'].phone_number);
    handleChange('contactEmail')(user?.email || '');
  };

  return (
    <Button style={{ borderRadius: 5 }} onClick={(): void => handleSetMyContactInfo()}>
      Use My Contact Info
    </Button>
  );
};
