import { Row, Space, Spin, Typography } from 'antd';
import { Loader } from 'components/common/Loader';
import { NFOQuantityInput } from 'components/common/NFOQuantityInput';
import { useField, useFormikContext } from 'formik';
import { InvOnHandResp } from 'models/DeploymentRequestConfig';
import { ProductPayload } from 'models/Product';
import { SparingRequestPayload, SublinePayload } from 'models/SparingRequests';
import { FC } from 'react';
import { useGetInventoryQuery } from 'redux/services/mrJohnson/mrJohnsonApi';
import { useAppSelector } from 'redux/store';

type Props = {
  record: ProductPayload;
  idx: number;
};

export const NFORow: FC<Props> = ({ record, idx }) => {
  const { values } = useFormikContext<SparingRequestPayload>();
  const { fullShippingAddress } = useAppSelector((state) => state.form);
  const [{ value }] = useField<SublinePayload[]>(`lineItems[${idx}].subLines`);

  const handleGetTotalQuantity = (allInventoryTotals: SublinePayload[]): number => {
    if (allInventoryTotals.length === 0) return 0;
    else {
      const sumTotal = (arr: SublinePayload[]): number => arr.reduce((sum, { quantity }) => sum + (quantity ?? 0), 0);
      const totalInventory = sumTotal(allInventoryTotals);

      return totalInventory;
    }
  };

  const { data, isLoading, isError } = useGetInventoryQuery(
    {
      product: record.productNumber.toUpperCase(),
      typeId: values.sparingRequestTypeId as string,
      payload: { shippingAddress: fullShippingAddress as any, sparingDeliveryOption: 'NextFlightOut' }
    },
    { skip: !values.sparingRequestTypeId || !fullShippingAddress }
  );

  if (handleGetTotalQuantity(value ?? []) === record.quantity && !value.some((subline) => subline.serviceLevelAgreement.toLowerCase() === 'NextFlightOut'.toLowerCase()))
    return (
      <Row style={{ height: 75, width: '100%' }} align={'middle'} justify={'center'}>
        <Typography.Text>Max quantity reached</Typography.Text>
      </Row>
    );

  const handleGetTotalQuantityAvailable = (allInventoryTotals: InvOnHandResp[]): number => {
    if (allInventoryTotals.length === 0) return 0;
    else {
      const sumTotal = (arr: any[]) => arr.reduce((sum, { quantityAvailable }) => sum + quantityAvailable, 0);
      const totalInventory = sumTotal(allInventoryTotals);

      return totalInventory;
    }
  };

  return (
    <Spin spinning={isLoading} indicator={<Loader small />} style={{ width: '100%' }}>
      <Space direction="vertical" style={{ height: 75 }} size={10}>
        <NFOQuantityInput disabled={handleGetTotalQuantityAvailable(data ?? []) === 0} idx={idx} record={record} />
        <Typography.Text type={isError ? 'danger' : undefined}>{isError ? 'Error' : `${handleGetTotalQuantityAvailable(data ?? []) ?? 0} available`}</Typography.Text>
      </Space>
    </Spin>
  );
};
