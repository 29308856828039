import { SearchOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { useFormikContext } from 'formik';
import { DeploymentRequestPayload } from 'models/DeploymentRequest';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setProductsView } from 'redux/slices/formSlice';
import { useAppSelector } from 'redux/store';
import '../../HeaderFilters/CountStyle.css';

const CartMenu = (): JSX.Element => {
  const dispatch = useDispatch();
  const { values } = useFormikContext<DeploymentRequestPayload>();
  const { productsView } = useAppSelector((state) => state.form);

  const itemCount = values.lineItems?.map((product) => product.quantity).reduce((previousQuantity, currentQuantity) => previousQuantity + currentQuantity, 0);

  // Clears state on unmount
  useEffect(() => {
    return (): void => {
      dispatch(setProductsView('grid'));
    };
  }, []);

  return (
    <Menu disabledOverflow={true} mode="horizontal" activeKey={productsView} openKeys={[productsView]} selectedKeys={[productsView]}>
      <Menu.Item key="grid" icon={<SearchOutlined />} onClick={(): any => dispatch(setProductsView('grid'))}>
        Search
      </Menu.Item>
      <Menu.Item key="cart" icon={<ShoppingCartOutlined />} onClick={(): any => dispatch(setProductsView('cart'))}>
        View Cart
        {itemCount <= 0 || !itemCount ? null : <span className="count">{itemCount}</span>}
      </Menu.Item>
    </Menu>
  );
};

export default CartMenu;
