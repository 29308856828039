import { Card, Col, Row, Typography } from 'antd';
import { useFormikContext } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { SparingRequestPayload } from 'models/SparingRequests';
import { useParams } from 'react-router-dom';
import { useGetSparingRequestConfigurationTypesQuery } from 'redux/services/calvin/sparingRequestConfigTypes';
import { colors, toRgba } from 'styles/colors';
const { Text, Title } = Typography;

const styles: InlineStylesModel = {
  header: {
    backgroundColor: toRgba(colors.romanSilver, 0.4)
    // height: '18%'
  },
  infoText: {}
};

export const RequestInfoContent = (): JSX.Element => {
  const { values } = useFormikContext<SparingRequestPayload>();
  const { id } = useParams();
  const { data: requestTypeData } = useGetSparingRequestConfigurationTypesQuery();
  // const [selectedRequestRuleTypeName, setSelectedRequestRuleTypeName] = useState(requestTypeData?.data.find((type) => values.shippingLocationId === type.id)?.name ?? '');

  const typeName = requestTypeData?.data.find((type) => values.sparingRequestTypeId === type.id)?.name ?? '';

  // useEffect(() => {
  // const requestTypeName = requestTypeData?.data.find((type) => values.deploymentRequestTypeId === type.id)?.name || null;

  //   if (requestTypeName) setSelectedRequestRuleTypeName(requestTypeName);
  // }, [values.deploymentRequestTypeId]);

  const headerInfoCardJSX = (
    <Card
      style={{ height: '100%', width: '100%' }}
      bodyStyle={{ marginBottom: 0, paddingBottom: 0, height: '82%' }}
      bordered={false}
      headStyle={styles.header}
      title={
        <Title level={4} style={{ fontWeight: 'normal' }}>
          Request Identification
        </Title>
      }>
      <Row gutter={16} style={{ height: '100%' }}>
        <Col span={8}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Request ID:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{values.requestNumber || 'AUTOGENERATED'}</Typography.Paragraph>
          <Typography.Title level={5} style={styles.infoTitle}>
            Spare Type:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{typeName}</Typography.Paragraph>
          {/* <Typography.Title level={5} style={styles.infoTitle}>
            Status:
          </Typography.Title> */}
          {/* <Typography.Paragraph style={styles.infoText}>{values.status.toUpperCase()}</Typography.Paragraph> */}
        </Col>
        <Col span={8}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Primary Refernece Number:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{values.customerReferenceNumber || 'n/a'}</Typography.Paragraph>
          {/* <Typography.Title level={5} style={styles.infoTitle}>
            Spare Type:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{typeName}</Typography.Paragraph> */}
        </Col>
        <Col span={8}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Secondary Reference Number:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{values.customerPurchaseOrderNumber || 'n/a'}</Typography.Paragraph>
          {/* <Typography.Title level={5} style={styles.infoTitle}>
            Project Name:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{values.project ? 'projectName' : 'n/a'}</Typography.Paragraph> */}
        </Col>
        {/* <Col span={24} style={{ position: 'absolute', bottom: 0, left: 0 }}>
          {id ? <Typography.Paragraph style={{ color: toRgba(colors.raisinBlackDark, 0.3) }}>Deployment Request ID: {id}</Typography.Paragraph> : null}
        </Col> */}
      </Row>
    </Card>
  );

  return (
    <>{headerInfoCardJSX}</>
    // <Card
    //   headStyle={styles.header}
    //   title={
    //     <Title level={4} style={{ fontWeight: 'normal' }}>
    //       Request Info
    //     </Title>
    //   }
    //   style={{ height: '100%' }}>
    //   <Row gutter={[5, 5]}>
    //     <Col span={10}>
    //       <Title level={5} style={{ margin: 0 }}>
    //         {DeploymentRequestLabels.requestNumber}
    //       </Title>
    //     </Col>
    //     <Col span={14} style={{ textAlign: 'right' }}>
    //       <Text style={{ color: 'rgba(0,0,0,.4)' }}>{id ? values.requestNumber : 'AUTOGENERATED ID'}</Text>
    //     </Col>
    //     <Col span={10}>
    //       <Title level={5} style={{ margin: 0 }}>
    //         {DeploymentRequestLabels.customerReferenceNumber}
    //       </Title>
    //     </Col>
    //     <Col span={14} style={{ textAlign: 'right' }}>
    //       <Text>{values.customerReferenceNumber}</Text>
    //     </Col>
    //     <Col span={10}>
    //       <Title level={5} style={{ margin: 0 }}>
    //         Install Location
    //       </Title>
    //     </Col>
    //     <Col span={14}>
    //       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
    //         <Text>{values.fullInstallAddress?.street1}</Text>
    //         <Text>
    //           {values.fullInstallAddress?.city}, {values.fullInstallAddress?.subdivision.localCode} {values.fullInstallAddress?.postalCode}
    //         </Text>
    //       </div>
    //     </Col>
    //     <Col span={10}>
    //       <Title level={5} style={{ margin: 0 }}>
    //         {DeploymentRequestLabels.isOrderReleased}
    //       </Title>
    //     </Col>
    //     <Col span={14} style={{ textAlign: 'right' }}>
    //       <Tag color={values.isOrderReleased ? 'green' : 'red'}>{values.isOrderReleased ? 'TRUE' : 'FALSE'}</Tag>
    //     </Col>
    //     <Col span={10}>
    //       <Title level={5} style={{ margin: 0 }}>
    //         {DeploymentRequestLabels.isLabConfigurationRequired}
    //       </Title>
    //     </Col>
    //     <Col span={14} style={{ textAlign: 'right' }}>
    //       <Tag color={values.isLabConfigurationRequired ? 'green' : 'red'}>{values.isLabConfigurationRequired ? 'TRUE' : 'FALSE'}</Tag>
    //     </Col>
    //   </Row>
    // </Card>
  );
};
