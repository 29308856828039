import { Col, Empty, Row, Spin } from 'antd';
import { LoadingMore } from 'components/common/LoadMore';
import { Loader } from 'components/common/Loader';
import WimsicalError from 'components/common/WimsicalError/WimsicalError';
import { formatErrorMessage } from 'helpers/formatErrorMessage';
import { QueryErrorModel } from 'models/ErrorModel';
import InfiniteScroll from 'react-infinite-scroll-component';
import { incrementOffset } from 'redux/services/twittlebugs/sparingParams';
import { useGetSparingRequestsQuery } from 'redux/services/twittlebugs/sparingRequestsApi';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { RecoverDeploymentCard } from './RecoverDeploymentCard';

export const DeploymentsGrid = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { sparingRequestsParams } = useAppSelector((state) => state);
  const { data, isError, isLoading, isFetching, error } = useGetSparingRequestsQuery({ ...sparingRequestsParams, returnDeletedDataOnly: true });

  const dispatch = useAppDispatch();

  /* ******************** Functions / Variables ******************** */
  const handleGetMore = (): void => {
    dispatch(incrementOffset());
  };

  /* ******************** Error Render ******************** */
  if (isError) {
    const err = error as QueryErrorModel;

    return <WimsicalError title={formatErrorMessage(typeof err.status === 'number' ? err.status : 400)} subTitle={err?.data?.errorMessage ? err.data.errorMessage : ''} statusCode={err.status} />;
  }

  /* ******************** Loading Render ******************** */
  if (isLoading || !data) {
    return (
      <Row style={{ marginTop: 8 }} gutter={[16, 16]}>
        <Loader />
      </Row>
    );
  }

  /* ******************** Data Render ******************** */
  const hasMore = data.totalCount > sparingRequestsParams.offset;

  return (
    <Spin spinning={isFetching} style={{ width: '100%' }} indicator={<Loader />}>
      <InfiniteScroll style={{ overflowX: 'clip' }} dataLength={data.data.length} next={handleGetMore} hasMore={hasMore} loader={<LoadingMore />} scrollableTarget="scrollableDiv">
        <Row style={{ marginTop: 8 }} gutter={[16, 16]}>
          {data.data.map((sparingRequest) => (
            <Col xs={24} md={12} lg={6} key={sparingRequest.id}>
              <RecoverDeploymentCard sparingRequest={sparingRequest} />
            </Col>
          ))}
        </Row>
        {!data.totalCount && <Empty />}
      </InfiniteScroll>
    </Spin>
  );
};
