import { InboxOutlined } from '@ant-design/icons';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { message, UploadProps } from 'antd';
import { RcFile } from 'antd/lib/upload';
import Dragger from 'antd/lib/upload/Dragger';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetSparingRequestQuery } from 'redux/services/twittlebugs/sparingRequestsApi';
import { addAttachment, removeAttachment } from 'redux/slices/attachmentsSlice';
import { useAppSelector } from 'redux/store';

export const AttachmentUpload = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data: deploymentRequest } = useGetSparingRequestQuery(id ?? skipToken);
  const { attachments } = useAppSelector((state) => state.attachments);

  /* ******************** Functions ******************** */

  const editedNames = deploymentRequest?.attachments.map((attachment) => attachment.name.slice(attachment.name.indexOf('-') + 1));

  const props: UploadProps = {
    multiple: true,
    customRequest: ({ file }) => {
      const fileToUpload = file as RcFile;

      if (attachments.find((uploadedFile) => uploadedFile.name === fileToUpload.name)) message.error(`File ${fileToUpload.name} has already been added.`);
      else if (editedNames?.find((uploadedFile) => uploadedFile === fileToUpload.name)) message.error(`File ${fileToUpload.name} has already been uploaded.`);
      else dispatch(addAttachment(fileToUpload));
    },
    onRemove(file) {
      const fileToDelete = file as RcFile;

      dispatch(removeAttachment(fileToDelete));
    }
  };

  return (
    <Dragger
      {...props}
      defaultFileList={[]}
      listType="picture"
      height={200}
      style={{ display: 'inline-block', width: '100%' }}
      fileList={attachments?.filter((file) => !editedNames?.includes(file.name))}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined style={{ color: '#5867dd' }} />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
    </Dragger>
  );
};
