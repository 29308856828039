import { ChoiceStatus, MappedChoiceStatus, MappedChoiceStatusRaw } from 'enums/GlobalEnums';
import { SparingOrder } from 'models/PostedSpareOrder';

export const formatChoiceStatus = (sparingOrder: SparingOrder): string => {
  switch (sparingOrder?.fulfillmentStatus) {
    case ChoiceStatus.Postack:
      return MappedChoiceStatus.Postack;

    case ChoiceStatus.Stage:
      return sparingOrder.serviceLevelAgreement.toLowerCase() === 'WillCall'.toLowerCase() ? MappedChoiceStatus.StageWillCall : MappedChoiceStatus.Stage;

    case ChoiceStatus.OrderShip:
      return MappedChoiceStatus.OrderShip;

    case ChoiceStatus.Pod:
      return MappedChoiceStatus.Pod;

    case ChoiceStatus.Redeliver:
      return MappedChoiceStatus.Redeliver;

    case ChoiceStatus.Packed:
      return MappedChoiceStatus.Packed;

    default:
      return 'Scheduling';
  }
};
export const formatChoiceStatusRaw = (sparingOrder: SparingOrder): string => {
  switch (sparingOrder?.fulfillmentStatus.toLowerCase()) {
    case MappedChoiceStatusRaw.Postack.toLowerCase():
      return MappedChoiceStatus.Postack;

    case MappedChoiceStatusRaw.Stage.toLowerCase():
      return MappedChoiceStatus.Stage;

    case MappedChoiceStatusRaw.StageWillCall.toLowerCase():
      return MappedChoiceStatus.StageWillCall;

    case MappedChoiceStatusRaw.OrderShip.toLowerCase():
      return MappedChoiceStatus.OrderShip;

    case MappedChoiceStatusRaw.Pod.toLowerCase():
      return MappedChoiceStatus.Pod;

    case MappedChoiceStatusRaw.Redeliver.toLowerCase():
      return MappedChoiceStatus.Redeliver;

    case MappedChoiceStatusRaw.Packed.toLowerCase():
      return MappedChoiceStatus.Packed;

    case MappedChoiceStatusRaw.Shipped.toLowerCase():
      return MappedChoiceStatus.Shipped;

    case MappedChoiceStatusRaw.Cancelled.toLowerCase():
      return MappedChoiceStatus.Cancelled;

    default:
      return 'Scheduling';
  }
};
