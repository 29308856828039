import { Button, Col, Row, Space, Typography } from 'antd';
import { AddDeliveryAddressModal } from 'components/createDeliveryAddress/AddDeliveryAddressModal';
import { useFormikContext } from 'formik';
import { DeploymentRequestLabels, DeploymentRequestPayload } from 'models/DeploymentRequest';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { colors, toRgba } from 'styles/colors';
import { LocationsFilters } from '../common';
const { Text, Title } = Typography;

const styles: InlineStylesModel = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: toRgba(colors.royalBlueLight, 0.4),
    justifyContent: 'space-between',
    width: '100%',
    padding: 24,
    borderBottom: '2px solid rgba(0,0,0,.1)',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10
  },
  title: {
    margin: 0,
    fontWeight: 'normal'
  },
  nextButton: {
    margin: 'auto',
    borderRadius: '5px'
  }
};

export const HeaderControls = (): JSX.Element => {
  const nav = useNavigate();
  const { id } = useParams();
  const { values } = useFormikContext<DeploymentRequestPayload>();
  const [showAddAddressModal, _setShowAddAddressModal] = useState(false);

  const selectedStatus = values.installAddressId !== null && values.installAddressId !== '';

  const handleContinueButton = (): void => {
    if (selectedStatus) {
      id ? nav(`/edit/${id}/shipping-location`) : nav('/create/shipping-location');
    } else {
      alert('no valid location selected...');
    }
  };

  return (
    <Row align="middle" style={styles.container}>
      <Col span={6}>
        <Space style={{ flex: 1, justifyContent: 'center' }} size={0} direction="vertical">
          <Title level={4} style={styles.title}>
            {'Search For '} {DeploymentRequestLabels.installAddress}
          </Title>
        </Space>
      </Col>
      <Col span={18} style={{ textAlign: 'right' }}>
        <Space>
          <LocationsFilters />
          <Button type="primary" style={styles.addAddressButton} onClick={(): void => _setShowAddAddressModal(true)}>
            Add Manual Address
          </Button>

          <AddDeliveryAddressModal
            addressTypes={['Install']}
            // addressType="outage"
            // configData={configData}
            show={showAddAddressModal}
            key={1}
            onClose={(): void => {
              _setShowAddAddressModal(false);
            }}
          />
        </Space>
      </Col>
    </Row>
  );
};
