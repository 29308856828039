import { Tooltip, Typography } from 'antd';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { SparingRequest } from 'models/SparingRequests';
import * as React from 'react';
const { Text, Title } = Typography;

const styles: InlineStylesModel = {
  text: {
    margin: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    verticalAlign: 'top'
  }
};

interface Props {
  deployment: SparingRequest;
}

// tslint:disable-next-line:variable-name
export const ShippingLocation: React.FC<Props> = (props) => {
  const { deployment } = props;
  const { shippingLocation } = deployment;

  if (shippingLocation === null) {
    return <Text>Pending</Text>;
  }

  const { code, city, street1, postalCode, subdivisionLocalCode } = shippingLocation;

  const toolTipJSX = (
    <div style={{ display: 'flex', flexDirection: 'column', width: 300 }}>
      <Text style={{ color: 'white' }}>Shipping Address</Text>
      <Text style={{ color: 'white' }}>{code}</Text>
      <Text style={{ color: 'white' }}>{street1}</Text>
      <Text style={{ color: 'white' }}>
        {city}, {subdivisionLocalCode} {postalCode}
      </Text>
    </div>
  );

  return (
    <Tooltip title={toolTipJSX}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Text type="secondary" style={styles.text}>
          {code}
        </Text>
        <Text type="secondary" style={styles.text}>
          {street1}
        </Text>
        <Text type="secondary" style={styles.text}>
          {city}, {subdivisionLocalCode} {postalCode}
        </Text>
      </div>
    </Tooltip>
  );
};
