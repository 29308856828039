import { ArrowLeftOutlined } from '@ant-design/icons';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query';
import { Button, Dropdown, MenuProps, Modal, Space, Typography, message } from 'antd';
import { useFormikContext } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { SparingRequestPayload } from 'models/SparingRequests';
import { useDeleteSparingRequestMutation, useGetSparingRequestQuery } from 'redux/services/twittlebugs/sparingRequestsApi';
import { setIsExit, setIsPost } from 'redux/slices/formSlice';
import { colors, toRgb } from 'styles/colors';
import '../../../styles/checkmarkStyle.less';
import '../../../styles/waveAnimation.css';

const styles: InlineStylesModel = {
  container: {
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: 8
  },
  draftButton: {
    color: toRgb(colors.raisinBlackLight),
    backgroundColor: '#EED202',
    border: 'none'
  },
  requestButton: {
    color: toRgb(colors.culturedWhite),
    backgroundColor: toRgb(colors.illuminatingEmerald),
    border: 'none'
  }
};

export enum CreateRequestMenu {
  RequestDetails = 'request-details',
  InstallLocation = 'install-location',
  Products = 'products',
  ShippingLocation = 'shipping-location',
  ShippingDetails = 'shipping-details',
  NotesSection = 'more-details',
  ReviewOrder = 'review-order'
}

export interface LineItemErrorObject {
  key: string;
  lineProductName: string;
  lineStatus?: boolean;
  lineMessage?: string;
  error?: FetchBaseQueryError;
}

export const ActionControls = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const nav = useNavigate();
  const { id } = useParams();
  const { pathname } = useLocation();
  const { data } = useGetSparingRequestQuery(id || skipToken);
  const { values, isSubmitting, submitForm } = useFormikContext<SparingRequestPayload>();
  const [deleteSparingRequest] = useDeleteSparingRequestMutation();

  const dispatch = useDispatch();

  // const isSlaValid = values.lineItems.every((line) => line.quantityOrdered === line.quantity && line.serviceLevelAgreement);

  const isSlaValid = values.lineItems
    .map((line) => {
      if (!line?.subLines?.length) return false;
      const totalSublineQty = (line?.subLines ?? [])?.reduce((sum, curr) => sum + (curr.quantity ?? 0), 0);

      return totalSublineQty === line.quantity;
    })
    .every((item) => item);

  const isPostValid = Boolean(
    values.sparingRequestTypeId &&
      values.shippingLocationId &&
      values.outageLocationId &&
      !!values.lineItems?.length &&
      isSlaValid &&
      values.contactPhoneNumber &&
      values.contactName &&
      values.contactEmail
  );

  /* ******************** Functions ******************** */

  const handleGoBack = (): void => {
    nav('/');
  };

  // const currentLocation = useMemo(() => {
  //   if (pathname.includes(CreateRequestMenu.InstallLocation)) {
  //     return CreateRequestMenu.InstallLocation;
  //   } else if (pathname.includes(CreateRequestMenu.Products)) {
  //     return CreateRequestMenu.Products;
  //   } else if (pathname.includes(CreateRequestMenu.ShippingLocation)) {
  //     return CreateRequestMenu.ShippingLocation;
  //   } else if (pathname.includes(CreateRequestMenu.ShippingDetails)) {
  //     return CreateRequestMenu.ShippingDetails;
  //   } else if (pathname.includes(CreateRequestMenu.NotesSection)) {
  //     return CreateRequestMenu.NotesSection;
  //   } else if (pathname.includes(CreateRequestMenu.ReviewOrder)) {
  //     return CreateRequestMenu.ReviewOrder;
  //   } else if (pathname.includes(CreateRequestMenu.RequestDetails)) {
  //     return CreateRequestMenu.RequestDetails;
  //   } else if (pathname.includes('request-info')) {
  //     return 'request-info';
  //   } else {
  //     return '';
  //   }
  // }, [pathname]);

  const handleSaveAndExit = (): void => {
    dispatch(setIsExit(true));
    submitForm();
  };

  const handleDelete: React.MouseEventHandler<HTMLElement> = async (event): Promise<void> => {
    if (id) {
      Modal.error({
        width: 600,
        okText: 'Delete',
        okType: 'danger',
        onOk: async () => {
          try {
            event.stopPropagation();
            await deleteSparingRequest({ id, params: { wouldYouLikeToPlayAGame: false } });
            message.success('Sparing request successfully deleted');
            nav('/');
          } catch (error) {
            message.error((error as { data: { errorMessage: string } }).data.errorMessage);
          }
        },
        cancelText: 'Cancel',
        onCancel: () => {
          console.log('canceled popup');
        },
        closable: true,
        title: <Typography.Title level={5}>Delete Request {data?.requestNumber} ?</Typography.Title>,
        content: <Typography.Text>Are you sure you want to delete this Sparing Request? You can still recover it in the Recycle Bin...</Typography.Text>
      });
    }
  };

  const items: MenuProps['items'] = [
    {
      label: 'Save',
      key: '1',
      onClick: submitForm
    }
  ];

  /* ******************** Render ******************** */
  return (
    <Space size="large" style={styles.container}>
      <Typography.Title style={{ marginBottom: 0 }} level={4}>
        {id ? 'Edit Sparing Request' : 'Create Sparing Request'}
      </Typography.Title>
      <Space size="small">
        <Button onClick={handleGoBack} icon={<ArrowLeftOutlined />}>
          Back to Search
        </Button>
        {id && (
          <Button onClick={handleDelete} type="ghost" danger>
            Delete
          </Button>
        )}
        <Dropdown.Button type="primary" onClick={handleSaveAndExit} loading={isSubmitting} menu={{ items }}>
          Save &amp; Close
        </Dropdown.Button>
        <Button
          style={isPostValid ? { background: 'rgb(78, 147, 122)', color: 'white' } : undefined}
          onClick={(): void => {
            dispatch(setIsPost(true));
            submitForm();
          }}
          disabled={!isPostValid}
          loading={isSubmitting}>
          Post Request
        </Button>
      </Space>
    </Space>
  );
};
