import { DatePicker, InputProps } from 'antd';
import { Field, FieldProps } from 'formik';
import moment from 'moment';
import { FC } from 'react';
import { FormLabel } from './FormLabel';

interface Props extends InputProps {
  fieldName: string;
  placeholder?: string;
  type?: string;
  label: string;
}

export const FieldInputDatePicker: FC<Props> = ({ fieldName, placeholder, label }) => {
  return (
    <Field name={fieldName} placeholder={placeholder}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { handleBlur, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta
      }: FieldProps): JSX.Element => {
        return (
          <div>
            <FormLabel label={label} />
            <DatePicker
              // {...field}
              showTime={{ format: 'HH:mm' }}
              onClick={(e): void => e.stopPropagation()}
              format={'MM/DD/YYYY HH:mm'}
              value={meta.value ? moment.utc(meta.value) : undefined}
              // onChange={(date, dateString) =>}
              onSelect={(date) => setFieldValue(fieldName, moment(date).format('YYYY-MM-DD HH:mm'))}
              onBlur={handleBlur(fieldName)}
              style={{ width: '100%' }}
            />
            <div className={meta.touched && meta.error ? 'field-error-show' : 'field-error-hide'}>{meta.error}</div>
          </div>
        );
      }}
    </Field>
  );
};
