import { About } from 'pages/About';
import { ContactInfoPage, CreateDeploymentRequestLayout, OutageLocationPage, ProductsPage, RequestDetailsPage, RequestInfoPage, ReviewOrder, ShippingLocationPage } from 'pages/Create';
import { DefectiveReturnsStep } from 'pages/Create/DefectiveReturnsStep';
import { ServiceLevelPage } from 'pages/Create/ServiceLevel';
import { DefectiveReturnsPage } from 'pages/Defective/DefectiveReturnsPage';
import { EditDeploymentRequestLayout } from 'pages/Edit';
import { PostedPage } from 'pages/Edit/PostedPage';
import { EditProfilePage } from 'pages/EditProfile';
import { RecoverPage } from 'pages/Recover';
import { ReleaseNotesPage } from 'pages/releaseNotes/ReleaseNotesPage';
import { Reports } from 'pages/Reports';
import { SearchPage } from 'pages/Search';
import { Route, Routes } from 'react-router-dom';

export const MainRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<SearchPage />} />
      <Route path="/search" element={<SearchPage />} />
      <Route path="/about" element={<About />} />
      <Route path="/profile" element={<EditProfilePage />} />
      <Route path="/reports" element={<Reports />} />
      <Route path="/posted/:id" element={<PostedPage />} />
      <Route path=":sparingId/defective-returns" element={<DefectiveReturnsPage />} />
      <Route path="/release-notes/:version" element={<ReleaseNotesPage />} />
      <Route path="create" element={<CreateDeploymentRequestLayout />}>
        <Route index element={<RequestDetailsPage requestType="create" />} />
        <Route path="outage-location" element={<OutageLocationPage />} />
        <Route path="products" element={<ProductsPage />} />
        <Route path="shipping-location" element={<ShippingLocationPage />} />
        <Route path="request-info" element={<RequestInfoPage />} />
        <Route path="contact-info" element={<ContactInfoPage />} />
        <Route path="service-level" element={<ServiceLevelPage />} />
        <Route path="defective-returns" element={<DefectiveReturnsStep />} />
        <Route path="review-order" element={<ReviewOrder />} />
      </Route>
      <Route path="edit/:id" element={<EditDeploymentRequestLayout />}>
        <Route index element={<RequestDetailsPage requestType="edit" />} />
        <Route path="outage-location" element={<OutageLocationPage />} />
        <Route path="products" element={<ProductsPage />} />
        <Route path="shipping-location" element={<ShippingLocationPage />} />
        <Route path="request-info" element={<RequestInfoPage />} />
        <Route path="contact-info" element={<ContactInfoPage />} />
        <Route path="defective-returns" element={<DefectiveReturnsStep />} />
        <Route path="service-level" element={<ServiceLevelPage />} />
        <Route path="review-order" element={<ReviewOrder />} />
      </Route>
      <Route path="recover" element={<RecoverPage />} />
    </Routes>
  );
};
