import { Form, Input, InputProps } from 'antd';
import { FormLabel } from './FormLabel';

interface Props extends InputProps {
  label: string;
}

export const TextInputMock = ({ label, ...rest }: Props): JSX.Element => {
  return (
    <Form.Item labelAlign="left" label={<FormLabel label={label} />} style={{ marginBottom: 0, color: 'rgba(0,0,0,0.85)' }}>
      <Input {...rest} />
    </Form.Item>
  );
};
