import { Col, Divider, Row, Space, Typography } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { ReactComponent as MdsiLogo } from 'assets/mdsi-logo.svg';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'redux/store';
import { UserDropdown } from './UserDropdown';

export const PageHeader = (): JSX.Element => {
  const { user } = useAppSelector((state) => state.app);
  const { xs } = useBreakpoint();

  const styles: InlineStylesModel = {
    container: {
      maxWidth: '1920px',
      margin: 'auto',
      padding: '40px 50px 40px 40px',
      flex: 1,
      alignItems: 'center',
      flexDirection: 'row'
    },
    appName: {
      display: 'flex',
      cursor: 'pointer',
      color: '#84859a',
      alignItems: 'flex-end',
      flex: 1,
      padding: 0
    },
    mainContainer: {
      color: 'white'
    },
    link: {
      color: '#84859a'
    },
    userMenu: {
      fontSize: '15px'
    }
  };

  return (
    <Row style={styles.container}>
      <Col style={styles.appName}>
        <a href={process.env.REACT_APP_ERNIE_REDIRECT_URL} style={styles.link}>
          <MdsiLogo height="18" width="85" />
        </a>
        {!xs && (
          <a href={process.env.REACT_APP_ERNIE_REDIRECT_URL}>
            <Typography.Paragraph style={{ fontWeight: 'normal', color: 'gray', margin: 0, fontSize: '24px' }}>Acuity</Typography.Paragraph>
          </a>
        )}
        <Divider type="vertical" style={{ backgroundColor: 'gray', width: 2, height: 20 }} />
        <Link to="/" style={styles.link}>
          <Typography.Paragraph style={{ fontWeight: 'normal', color: 'gray', margin: 0, fontSize: '24px' }}>Sparing Requests</Typography.Paragraph>
        </Link>
      </Col>
      <Col>
        <Row align="middle" style={styles.userMenu}>
          <Space>
            {!xs && user?.name?.toUpperCase()}
            <UserDropdown />
          </Space>
        </Row>
      </Col>
    </Row>
  );
};
