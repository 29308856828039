import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Col, Row, Space, Typography } from 'antd';
import { useFormikContext } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { SparingRequestPayload } from 'models/SparingRequests';
import { useParams } from 'react-router-dom';
import { useGetSparingRequestConfigurationTypesQuery } from 'redux/services/calvin/sparingRequestConfigTypes';
import { useGetSparingRequestQuery } from 'redux/services/twittlebugs/sparingRequestsApi';
import { useAppSelector } from 'redux/store';

const styles: InlineStylesModel = {
  headerTitle: {
    fontWeight: 'bold',
    color: '#2F5596'
  }
};

export const DeploymentRequestInfo = (): JSX.Element => {
  const { values } = useFormikContext<SparingRequestPayload>();
  const { id } = useParams();

  // const { data } = useGetSparingRequestConfigurationTypeQuery(values.sparingRequestTypeId ?? skipToken);

  const { data: typesData, isLoading } = useGetSparingRequestConfigurationTypesQuery();
  const foundType = typesData?.data.find((type) => type.id === values.sparingRequestTypeId);
  const { data } = useGetSparingRequestQuery(id || skipToken);

  const { fullOutageAddress, fullShippingAddress } = useAppSelector((state) => state.form);

  const shippingAddress = fullShippingAddress
    ? `${fullShippingAddress.name}: ${fullShippingAddress.code}: ${fullShippingAddress.street1}, ${fullShippingAddress.city}, ${fullShippingAddress?.subdivisionLocalCode || ''}, ${
        fullShippingAddress.country || ''
      } ${fullShippingAddress.postalCode}`
    : '';

  const outageAddress = fullOutageAddress
    ? `${fullOutageAddress.name}: ${fullOutageAddress.code}: ${fullOutageAddress.street1}, ${fullOutageAddress.city}, ${fullOutageAddress?.subdivisionLocalCode || ''}, ${
        fullOutageAddress.country || ''
      } ${fullOutageAddress.postalCode}`
    : '';

  const findRequestNumber = (): string => {
    if (id && !values.requestNumber) return `${data?.requestNumber?.split('-')[0]}`;
    if (id) return `${data?.requestNumber?.split('-')[0]}--${values.requestNumber}`;
    if (!id && values.requestNumber) return `AUTOGENERATED--${values.requestNumber}`;

    return 'AUTOGENERATED';
  };

  const cartTotal = values.lineItems.reduce((acc, item) => item.quantity + acc, 0);

  return (
    <>
      <Row align="middle" gutter={[15, 15]} style={{ marginBottom: 10 }}>
        <Col>
          <Space>
            <Typography.Text style={styles.headerTitle}>Sparing Request Number: </Typography.Text>
            <Typography.Text>{findRequestNumber()}</Typography.Text>
          </Space>
        </Col>
        {values.customerReferenceNumber && (
          <Col>
            <Space>
              <Typography.Text style={styles.headerTitle}>Primary Reference Number: </Typography.Text>
              <Typography.Text>{values.customerReferenceNumber}</Typography.Text>
            </Space>
          </Col>
        )}
        {values.customerPurchaseOrderNumber && (
          <Col>
            <Space>
              <Typography.Text style={styles.headerTitle}>Secondary Reference Number: </Typography.Text>
              <Typography.Text>{values.customerPurchaseOrderNumber}</Typography.Text>
            </Space>
          </Col>
        )}
        {values.sparingRequestTypeId && foundType?.name && (
          <Col>
            <Space>
              <Typography.Text style={styles.headerTitle}>Spare Type: </Typography.Text>
              <Typography.Text>{foundType.name}</Typography.Text>
            </Space>
          </Col>
        )}
        {!!values.lineItems.length && (
          <Col>
            <Space>
              <Typography.Text style={styles.headerTitle}>Items in Cart: </Typography.Text>
              <Typography.Text>{cartTotal}</Typography.Text>
            </Space>
          </Col>
        )}
        {/* </Space> */}
      </Row>
      <Row align="middle" gutter={[0, 8]} style={{ marginBottom: 8 }}>
        {values.outageLocationId && outageAddress && (
          <Col span={24} xxl={24}>
            <Space>
              <Typography.Text style={styles.headerTitle}>Outage Address: </Typography.Text>
              <Typography.Text ellipsis={{ tooltip: '' }} style={{ textTransform: 'capitalize' }}>
                {outageAddress}
              </Typography.Text>
            </Space>
          </Col>
        )}
        {values.shippingLocationId && shippingAddress && (
          <Col span={24} xxl={24}>
            <Space>
              <Typography.Text style={styles.headerTitle}>Shipping Address: </Typography.Text>
              <Typography.Text ellipsis={{ tooltip: '' }} style={{ textTransform: 'capitalize' }}>
                {shippingAddress}
              </Typography.Text>
            </Space>
          </Col>
        )}
      </Row>
    </>
  );
};
