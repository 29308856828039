import { Card, Col, Row, Typography } from 'antd';
import { Option } from 'antd/lib/mentions';
import { useFormikContext } from 'formik';
import { DeploymentRequestPayload } from 'models/DeploymentRequest';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Address } from 'redux/services/chuckieSue/models/addresses';
import { useAppSelector } from 'redux/store';
import { colors, toRgba } from 'styles/colors';
import { ServiceLevelTable } from './ServiceLevelTable';
const { Title } = Typography;

const styles: InlineStylesModel = {
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: toRgba(colors.romanSilver, 0.4),
    justifyContent: 'space-between',
    width: '100%',
    padding: 24,
    borderBottom: '2px solid rgba(0,0,0,.1)'
  },
  headerTitle: {
    margin: 0,
    fontWeight: 'normal'
  },
  container: {
    paddingBottom: '20px'
  },
  nextButton: {
    borderRadius: '5px',
    marginBlock: 'auto'
  }
};

export const ServiceLevelPage = (): JSX.Element => {
  const { errors } = useFormikContext<DeploymentRequestPayload>();
  const nav = useNavigate();
  const { id } = useParams();

  const { chuckieSueAddressesParams } = useAppSelector((state) => state);

  // const { data } = useGetAddressesQuery({ ...chuckieSueAddressesParams, addressTypesInclude: [LocationAddressTypes.Warehouse] });
  const { values } = useFormikContext<DeploymentRequestPayload>();

  const [validLocalDelivery, setValidLocalDelivery] = useState<Address[]>([]);

  // const haversineDistance = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
  //   const toRad = (value: number): number => (value * Math.PI) / 180;

  //   const R = 6371;
  //   const dLat = toRad(lat2 - lat1);
  //   const dLon = toRad(lon2 - lon1);

  //   const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

  //   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  //   const distance = R * c;

  //   return distance * 0.621371;
  // };

  // console.log(data?.data[2], 'data');
  // console.log(values.fullShippingAddress, 'shipping');

  // data?.data.forEach((item) => {
  //   if (haversineDistance(item.latitude as number, item.longitude as number, values?.fullShippingAddress?.latitude as number, values.fullShippingAddress?.longitude as number) < 50) {
  //     setValidLocalDelivery((prev) => [...prev, item]);
  //   }
  // });

  // console.log(
  //   haversineDistance(data?.data[2].latitude as number, data?.data[2].longitude as number, values?.fullShippingAddress?.latitude as number, values.fullShippingAddress?.longitude as number),
  //   'distance in miles'
  // );

  const flatLineItems = values.lineItems.map(({ customerXRef, ...rest }) => ({ altItemId: customerXRef !== null ? customerXRef?.alternateItemId : '', ...rest }));

  const getSelectOptions = (index: number): JSX.Element => {
    return (
      <>
        <Option disabled={index === 1 || index === 2 || index === 3}>Local Delivery – 4 hours</Option>
        <Option>Local Delivery – 6 hours</Option>
        <Option>Local Delivery – Scheduled Time (outside SLA)</Option>
        <Option disabled={index === 1 || index === 2 || index === 3}>Will Call</Option>
        <Option>NBD (Next Business Day)</Option>
        <Option disabled={index === 1 || index === 2 || index === 3}>NFO (Next Flight Out)</Option>
      </>
    );
  };

  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <Card
          headStyle={{ backgroundColor: toRgba(colors.robinEggBlue, 0.4) }}
          title={
            <Row justify="space-between">
              <Col>
                <Title level={4} style={{ fontWeight: 'normal' }}>
                  Service Level Information
                </Title>
              </Col>
            </Row>
          }
          style={{ height: '100%' }}>
          {/* <Row gutter={[0, 10]}>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Row gutter={[60, 60]} style={{ marginBottom: 32 }}>
                    <Col style={{ width: 300 }}>
                      <Typography.Text>Product Number</Typography.Text>
                    </Col>
                    <Col style={{ width: 300 }}>Category</Col>
                    <Col style={{ width: 300 }}>Product Name</Col>
                    <Col style={{ width: 300 }}>Product Description</Col>
                    <Col style={{ width: 300 }}>Delivery Type</Col>
                  </Row>
                  <List
                    dataSource={values.lineItems}
                    renderItem={(item, idx): JSX.Element => (
                      <List.Item>
                        <Row gutter={[60, 60]}>
                          <Col style={{ width: 300 }}>{item.productNumber}</Col>
                          <Col style={{ width: 300 }}>{item.category}</Col>
                          <Col style={{ width: 300 }}>{item.productName}</Col>
                          <Col style={{ width: 300 }}>{item.productDescription}</Col>
                          <Col style={{ width: 300 }}>
                            <Select allowClear style={{ width: 300 }} dropdownMatchSelectWidth>
                              {getSelectOptions(idx)}
                            </Select>
                          </Col>
                        </Row>
                      </List.Item>
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row> */}
          <ServiceLevelTable />
        </Card>
      </Col>
    </Row>
  );
};
