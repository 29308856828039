import { Card, Col, Modal, Row, Tooltip, Typography } from 'antd';
import { useFormikContext } from 'formik';

import { InlineStylesModel } from 'models/InlineStylesModel';
import { SparingAddress, SparingRequestPayload } from 'models/SparingRequests';
import { setFullOutageAddress, setFullShippingAddress } from 'redux/slices/formSlice';
import { useAppDispatch } from 'redux/store';
import { colors, toRgba } from 'styles/colors';
import { ReactComponent as DeliveryIcon } from '../../../../../assets/delivery.svg';
import { ReactComponent as InstallIcon } from '../../../../../assets/install.svg';

interface Props {
  address?: SparingAddress;
  addressType: 'shipping' | 'outage';
}

const styles: InlineStylesModel = {
  container: {
    height: '100%'
  },
  cardBody: {
    borderRadius: 10,
    padding: 0
  },
  customerInfo: {
    padding: '10px 60px'
  },
  title: {
    fontSize: '.8rem',
    margin: 0,
    color: 'rgba(0, 0, 0, .4)'
  },
  titleWhite: {
    fontSize: '.8rem',
    margin: 0,
    color: 'rgba(255, 255, 255, .4)'
  },
  values: {
    fontSize: 16,
    fontWeight: 600
  },
  valuesWhite: {
    color: 'rgba(255, 255, 255, .9)',
    fontSize: 16,
    fontWeight: 600
  },
  minusButton: {
    color: '#F93943',
    borderColor: '#F93943'
  },
  selectedInstallStyles: {
    transition: '.25s all ease-out',
    background: toRgba(colors.royalBlueLight, 0.5),
    boxShadow: '0 0 6px rgba(0,0,0,.4)',
    borderRadius: 10
  },
  selectedShippingStyles: {
    transition: '.25s all ease-out',
    background: toRgba(colors.illuminatingEmerald, 0.5),
    boxShadow: '0 0 6px rgba(0,0,0,.4)',
    borderRadius: 10
  },
  removeButton: {
    margin: 'auto'
  }
};

export const SelectedLocationCard = ({ address, addressType }: Props): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { setFieldValue, values } = useFormikContext<SparingRequestPayload>();
  const dispatch = useAppDispatch();

  /* ******************** Functions ******************** */
  const handleRemoveInstallLocation = (): void => {
    if (addressType === 'outage') {
      setFieldValue('outageLocationId', null);
      dispatch(setFullOutageAddress(null));
    }

    if (addressType === 'shipping') {
      if (values.shippingLocationId && values.lineItems.length) {
        Modal.confirm({
          okText: 'Confirm',
          onOk: () => {
            setFieldValue('shippingLocationId', null);
            setFieldValue('lineItems', []);
            dispatch(setFullShippingAddress(null));
          },
          cancelText: 'Cancel',
          onCancel: () => {
            console.log('canceled popup');
          },
          closable: true,
          title: 'If you change the shipping location, the products will be removed from the cart. Are you sure you want to continue?'
        });
      } else {
        setFieldValue('shippingLocationId', null);
        dispatch(setFullShippingAddress(null));
      }
    }
  };

  if (!address) {
    return <></>;
  }

  return (
    <Card bordered={false} style={addressType === 'outage' ? styles.selectedInstallStyles : styles.selectedShippingStyles} bodyStyle={styles.cardBody} hoverable onClick={handleRemoveInstallLocation}>
      <Row style={{ borderRadius: 10 }}>
        <Col span={10} style={{ background: 'rgba(40,40,40,.6)', padding: '0px 20px', borderRadius: '10px 0 0 10px', paddingTop: '5px' }}>
          <Row gutter={[5, 0]} style={{ paddingTop: '10px' }}>
            {addressType === 'outage' ? (
              <Col>
                <Tooltip title="Install" placement="top" autoAdjustOverflow={false} overlayInnerStyle={styles.tooltipStyle}>
                  <InstallIcon width={20} />
                </Tooltip>
              </Col>
            ) : (
              <Col>
                <Tooltip title="Delivery" placement="top" autoAdjustOverflow={false} overlayInnerStyle={styles.tooltipStyle}>
                  <DeliveryIcon width={25} />
                </Tooltip>
              </Col>
            )}
          </Row>
          <Typography.Paragraph style={styles.titleWhite}>
            <Tooltip title="Address Code" placement="top" autoAdjustOverflow={false} overlayInnerStyle={styles.tooltipStyle}>
              {address.code || <span>&nbsp;</span>}
            </Tooltip>
          </Typography.Paragraph>
          <Typography.Paragraph style={styles.valuesWhite}>
            {' '}
            <Tooltip title="Address Name" placement="left" autoAdjustOverflow={false} overlayInnerStyle={styles.tooltipStyle}>
              {address.name || <span>&nbsp;</span>}
            </Tooltip>
          </Typography.Paragraph>
        </Col>
        <Col span={12}>
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              width: '81.25px',
              height: '45.625px',
              borderLeft: 'solid 40.625px rgba(40,40,40,.6)',
              borderBottom: 'solid 50.625px transparent',
              borderTop: 'solid 50.625px transparent'
            }}
          />
          <Col style={styles.customerInfo}>
            <Typography.Paragraph style={styles.titleWhite}>Address</Typography.Paragraph>
            <Typography.Paragraph style={styles.valuesWhite}>
              {address.street1} {address.city}, {address?.subdivisionLocalCode ?? ''} {address.countryAlpha2Code}
            </Typography.Paragraph>
          </Col>
        </Col>
      </Row>
    </Card>
  );
};
